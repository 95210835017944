import React, { useEffect } from "react";
import useStyles from "../../style";
import { renderErrorCreatePool } from "../../../../utils/validate";

function TokenomicsLink(props: any) {
  const classes = useStyles();
  const {
    register,
    setValue,
    errors,
    poolDetail,
    isEditDisabled
  } = props;
  const renderError = renderErrorCreatePool;

  const handleChangeTokenomicsLink = (e: any) => {
    setValue("tokenomics", e.target.value);
  };

  useEffect(() => {
    setValue("tokenomics", poolDetail?.tokenomics || "");
  }, [poolDetail, setValue]);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Tokenomics</label>
        <input
          type="text"
          name="tokenomics"
          defaultValue={poolDetail?.socialNetworkSetting?.tokenomics}
          ref={register({
            // required: true
          })}
          onChange={handleChangeTokenomicsLink}
          className={classes.formControlInput}
          disabled = {isEditDisabled}
          style = {{
            backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
          }}
        />
        <p className={classes.formErrorMessage}>
          {renderError(errors, "tokenomics")}
        </p>
      </div>
    </>
  );
}

export default TokenomicsLink;
