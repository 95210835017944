import React, { useState, useEffect } from "react";
import ConfirmDialog from "../../components/Base/ConfirmDialog";
import { getFlag, updatMaxCapStatus } from "../../request/config";
import { alertSuccess } from "../../store/actions/alert";
import { useDispatch } from "react-redux";
import { FormControl, Switch, Tooltip } from "@material-ui/core";

const EnableMaxCap = () => {
  const dispatch = useDispatch();
  const [macCapFeatFlag, setMaxCapFeatFlag] = useState(true);
  const [maxCapFlag, setMaxCapFlag] = useState<any>("");
  const [isOpenEditPopupMaxCap, setIsOpenEditPopupMaxCap] = useState(false);
  const [displayMaxCapLabel, setDisplayMaxCapLabel] = useState(true);
  const [localMaxCapFlag, setLocalMaxCapFlag] = useState(true);

  useEffect(() => {
    fetchFlags();
  }, []);

  const fetchFlags = async () => {
    try {
      const response = await getFlag();
      const flags = response.data;
      const currentROIFlag = flags?.find(
        (flag: any) => flag?.name === "Max-StakingPoints-For-Allocation"
      );

      if (response?.status === 200) {
        setMaxCapFlag(currentROIFlag);
        setMaxCapFeatFlag(currentROIFlag.value);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMaxCapSwitchChange = (event: any) => {
    const { checked } = event?.target;
    setLocalMaxCapFlag(checked);
    setIsOpenEditPopupMaxCap(true);
    setDisplayMaxCapLabel(checked);
  };

  const handleSubmitPopup = async () => {
    const name = maxCapFlag?.name;
    try {
      const response = await updatMaxCapStatus(
        { name, value: localMaxCapFlag },
        maxCapFlag?.id
      );
      fetchFlags();
      dispatch(alertSuccess("Successfull"));
      setIsOpenEditPopupMaxCap(false);
      setMaxCapFeatFlag(response.data.value);
    } catch (e) {
      console.log(e, "error");
    }
  };

  const handleCancel = () => {
    setIsOpenEditPopupMaxCap(false);
    setLocalMaxCapFlag(macCapFeatFlag);
  };

  return (
    <>
      <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
        <Tooltip
          title={
            <p>
              Currently for diamond tier users if a user has 1x diamond points
              and other has 2x diamond points so 2x will get double allocation
              then 1x and so on for 3x 4x... Turn on the max cap toggle and set
              the limit here then above these points all the users will get same
              allocation.
            </p>
          }
        >
          <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Switch
              onChange={handleMaxCapSwitchChange}
              checked={macCapFeatFlag}
            />
            <h4
              style={{
                marginRight: "10px",
                marginTop: "5px",
                fontSize: "14px",
              }}
            >
              Enable Max Cap
            </h4>
          </span>
        </Tooltip>
      </FormControl>
      <ConfirmDialog
        title={
          displayMaxCapLabel ? "Enable Current ROI" : "Disable Current ROI"
        }
        open={isOpenEditPopupMaxCap}
        confirmLoading={false}
        onConfirm={handleSubmitPopup}
        onCancel={handleCancel}
      >
        <div>
          Are you sure you want to {displayMaxCapLabel ? "enable" : "disable"}{" "}
          Max Cap?
        </div>
      </ConfirmDialog>
    </>
  );
};

export default EnableMaxCap;
