import React, { useEffect } from "react";
//@ts-ignore
import { NotificationContainer, NotificationManager} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useDispatch, useSelector } from "react-redux";
import {
  HashRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
  useLocation,
} from "react-router-dom";
import AppContainer from "./AppContainer";
import ErrorBoundary from "./components/Base/ErrorBoundary";
import PrivateRoute from "./components/Base/PrivateRoute";
import AdminCreate from "./pages/AdminList/AdminDetail/AdminCreate";
import AdminEdit from "./pages/AdminList/AdminDetail/AdminEdit";
import AdminList from "./pages/AdminList/AdminList";
import ErrorPage from "./pages/ErrorPage";
import KycUserCreate from "./pages/KycUserList/KycUserDetail/KycUserCreate";
import KycUserEdit from "./pages/KycUserList/KycUserDetail/KycUserEdit";
import KycUserList from "./pages/KycUserList/KycUserList";
import Login from "./pages/Login";
import NotFoundPage from "./pages/NotFoundPage";
import PoolCreate from "./pages/PoolCreate/PoolCreate";
import PoolEdit from "./pages/PoolCreate/PoolEdit";
import TBAPoolCreate from "./pages/PoolCreate/TBAPoolCreate";
import TBAPoolEdit from "./pages/PoolCreate/TBAPoolEdit";
import QuestionCategory from "./pages/Faq";
import Pools from "./pages/Pools";
import StakingPoolCreate from "./pages/StakingPoolCreate/PoolCreate";
import StakingPoolEdit from "./pages/StakingPoolCreate/PoolEdit";
import StakingPools from "./pages/StakingPools";
import Statistic from "./pages/Statistic";
import TBAPools from "./pages/TBAPools";
import TransactionPending from "./pages/TransactionPending";
import UserList from "./pages/UserList";
import { clearAlert } from "./store/actions/alert";
import { adminRoute } from "./utils";
import RPCListing from "./pages/RPCListing";
import RPCForm from "./pages/RPCForm";
import {
  ADMIN_LOGS,
  BLOG,
  DELEGATE_LIST,
  DISCLAIMER_TERMS,
  EXCHANGE_LIST,
  HIDE_STATISTIC,
  MAX_CAP,
  PERMISSIONS,
  RECEIVER_WALLETS_TAB,
  ROI_DASHBOARD,
  RPC,
  SOCIAL_MEDIA_STATS,
  TEAM_MEMBER_SECTION,
  TIERS_RANGE,
  WHITELIST_WALLETS_TAB,
} from "./constants";
import BlogListing from "./pages/BlogListing";
import BlogForm from "./pages/BlogForm";
import TiersListing from "./pages/TiersListing";
import TiersForm from "./pages/TiersForm";
import Config from "./pages/Config";
import WhiteListWalletsListing from "./pages/WhiteListWalletsListing";
import WhiteListWalletsForm from "./pages/WhiteListWalletsForm";
import Landing from "./pages/Landing";
import Menu from "./pages/Menu";
import MyTiers from "./pages/Tier";
import TermsOfService from "./pages/TermsAndServices";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ThemeOptions from "./pages/ThemeOptions";
import NeedHelp from "./pages/NeedHelp";
import SocialMediaLinks from "./pages/SocialMediaLinks";
import Faqs from "./pages/Faq";
import AdminLogsList from "./pages/AdminLogsList";
import SolanaMultiSig from "./pages/SolanaMultiSig";
import MultiSigDetail from "./pages/SolanaMultiSig/MultisIgDetail";
import AdminLogs from "./pages/AdminLogs";
import { getAdminDetail } from "./request/admin";
import ExchangeListing from "./pages/exchange-list/ExchangeList";
import ExchangeListForm from "./pages/exchange-list/ExchangeListForm";
import { PublicKey } from "@solana/web3.js";
import { useTypedSelector } from "./hooks/useTypedSelector";
import MaxCapForm from "./pages/MaxCapForm";
import DelegateList from "./pages/delegateList";
import DisclaimerList from "./pages/DisclaimerList/DisclaimerList";
import DisclaimerListForm from "./pages/DisclaimerList/DisclaimerListForm";
import UserTermsLogs from "./pages/UserTerms/UserTerms";
import StakingUserList2 from "./pages/StakingUserList2";
import SocailMediaStatsForm from "./pages/socailMediaStats/SocailMediaStatsForm";
import SocailMediaStats from "./pages/socailMediaStats/SocailMediaStats";
import BlockUsers from "./pages/BlockUsers";
import ReceiverWalletsListing from "./pages/ReceiverWalletsListing";
import ReceiverWalletsForm from "./pages/ReceiverWalletsForm";
import ROIPrediction from "./pages/RoiPrediction/details";
import ROIPredictionForm from "./pages/RoiPrediction/details";
import ROIPredictionList from "./pages/RoiPrediction/RoiList";
import TeamMemeberList from "./pages/TeamMemberList";

declare global {
  interface Window {
    solana?: any;
  }
}

/**
 * Main App routes.
 */
const Routes: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useSelector((state: any) => state.alert);
  const { data: loginUser } = useTypedSelector((state) => state.user);
  const { history } = props;

  useEffect(() => {
    const { type, message } = alert;
    if (type && message) {
      NotificationManager[type](message);
    }
  }, [alert]);

  useEffect(() => {
    history.listen((location, action) => {
      dispatch(clearAlert());
    });
  }, []);

  useEffect(() => {
    const getAdminInfo = async () => {
      try {
        const resObject = await getAdminDetail(loginUser.id);
        if (resObject.status === 200 && resObject?.data?.permissions) {
          localStorage.setItem("permissions", JSON.stringify(resObject?.data?.permissions));
          localStorage.setItem("role", JSON.stringify(resObject?.data?.role));
        }
      } catch (err) {}
    };

    if (loginUser) {
      PERMISSIONS && getAdminInfo();
    }
  }, [location, loginUser]);

  const solanaWallet = useSelector((state: any) => state?.solanaWallet);

  useEffect(() => {
    if (!window.solana) return;

    if (!solanaWallet.isConnected) return;

    window.solana.connect({ onlyIfTrusted: true }).catch(() => {});

    window.solana.on("connect", (publicKey: PublicKey) => {});

    window.solana.on("disconnect", () => {});

    window.solana.on("accountChanged", (publicKey: PublicKey | null) => {
      if (publicKey) {
      } else {
        window.solana.connect().catch((error: any) => {});
      }
    });

    return () => {
      window.solana.disconnect();
    };
  }, []);

  return (
    <div>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={`${adminRoute("/campaigns")}`} />} />
        <Route exact path={`${adminRoute()}`} render={() => <Redirect to={`${adminRoute("/campaigns")}`} />} />
        <PrivateRoute path={adminRoute("/landing")} exact component={Landing} />
        <PrivateRoute path={adminRoute("/faqs")} exact component={Faqs} />
        <PrivateRoute path={adminRoute("/menu")} exact component={Menu} />
        <PrivateRoute path={adminRoute("/tos")} exact component={TermsOfService} />
        <PrivateRoute path={adminRoute("/privacyPolicy")} exact component={PrivacyPolicy} />
        <PrivateRoute path={adminRoute("/ThemeOptions")} exact component={ThemeOptions} />
        <PrivateRoute path={adminRoute("/needHelp")} exact component={NeedHelp} />
        <PrivateRoute path={adminRoute("/socialMediaLinks")} exact component={SocialMediaLinks} />
        <PrivateRoute path={adminRoute("/tiers")} exact component={MyTiers} />
        <PrivateRoute path={adminRoute("/campaigns")} exact component={Pools} />
        <PrivateRoute path={adminRoute("/campaigns/add")} exact component={PoolCreate} />
        <PrivateRoute exact path={adminRoute("campaign-detail/:id")} component={PoolEdit} />

        {BLOG && <Route path={adminRoute("/blog")} component={BlogListing} />}

        {BLOG && <Route path={adminRoute("/add-blog")} component={BlogForm} />}
        {BLOG && <Route path={adminRoute("/edit-blog/:id")} component={BlogForm} />}
        <PrivateRoute exact path={adminRoute("/campaign-detail/pending/:id")} component={TransactionPending} />

        <PrivateRoute path={adminRoute("/tba-campaigns")} exact component={TBAPools} />
        <PrivateRoute path={adminRoute("/tba-campaigns/add")} exact component={TBAPoolCreate} />
        <PrivateRoute exact path={adminRoute("tba-campaign-detail/:id")} component={TBAPoolEdit} />
        <PrivateRoute exact path={adminRoute("/faq")} component={QuestionCategory} />

        <PrivateRoute path={adminRoute("/staking")} exact component={StakingPools} />
        <PrivateRoute path={adminRoute("/user-staking-list")} exact component={StakingUserList2} />
        <PrivateRoute path={adminRoute("/staking/add")} exact component={StakingPoolCreate} />
        <PrivateRoute path={adminRoute("/staking/:id")} exact component={StakingPoolEdit} />

        <Route path={adminRoute("/login")} component={Login} />

        <Route path={adminRoute("/users")} component={UserList} />

        <Route path={adminRoute("/block-users")} component={BlockUsers} />

        <Route path={adminRoute("/kyc-users")} component={KycUserList} />
        <Route exact path={adminRoute("/solana-multi-sig")} component={SolanaMultiSig} />
        <Route path={adminRoute("/solana-multi-sig/:id")} component={MultiSigDetail} />
        <Route path={adminRoute("/kyc-user-detail/:id")} component={KycUserEdit} />
        <Route path={adminRoute("/kyc-user-create")} component={KycUserCreate} />

        <Route path={adminRoute("/kyc-users")} component={KycUserList} />
        <Route exact path={adminRoute("/solana-multi-sig")} component={SolanaMultiSig} />
        <Route path={adminRoute("/solana-multi-sig/:id")} component={MultiSigDetail} />
        <Route path={adminRoute("/kyc-user-detail/:id")} component={KycUserEdit} />
        <Route path={adminRoute("/kyc-user-create")} component={KycUserCreate} />

        <Route path={adminRoute("/admins")} component={AdminList} />
        <PrivateRoute path={adminRoute("/admin-logs")} exact component={AdminLogs} />
        <Route path={adminRoute("/admin-detail/:id")} component={AdminEdit} />
        <Route path={adminRoute("/admin-create")} component={AdminCreate} />

        {HIDE_STATISTIC && <Route path={adminRoute("/statistic")} component={Statistic} />}

        {HIDE_STATISTIC && <Route path={adminRoute("/statistic")} component={Statistic} />}
        {DISCLAIMER_TERMS && <Route path={"/dashboard/user-terms-log"} component={UserTermsLogs} />}

        {SOCIAL_MEDIA_STATS && <Route path={"/dashboard/social-media-stats"} component={SocailMediaStats} />}
        {SOCIAL_MEDIA_STATS && <Route path={"/social-media-stats/edit/:id"} component={SocailMediaStatsForm} />}
        {SOCIAL_MEDIA_STATS && <Route path={"/social-media-stats/add"} component={SocailMediaStatsForm} />}

        {DISCLAIMER_TERMS && <Route path={"/dashboard/term-and-privacy"} component={DisclaimerList} />}

        <Route path={"//term-and-privacy/edit/:id"} component={DisclaimerListForm} />
        <Route path={"/term-and-privacy/add"} component={DisclaimerListForm} />
        {EXCHANGE_LIST && <Route path={"/dashboard/exchange-list"} component={ExchangeListing} />}
        {SOCIAL_MEDIA_STATS && <Route path={"/dashboard/social-media-stats"} component={SocailMediaStats} />}
        {SOCIAL_MEDIA_STATS && <Route path={"/social-media-stats/edit/:id"} component={SocailMediaStatsForm} />}
        {SOCIAL_MEDIA_STATS && <Route path={"/social-media-stats/add"} component={SocailMediaStatsForm} />}

        <Route path={"/exchange-list/edit/:id"} component={ExchangeListForm} />
        <Route path={"/exchange-list/add"} component={ExchangeListForm} />
        {DELEGATE_LIST && <Route path={"/dashboard/delegate-list"} component={DelegateList} />}
        {MAX_CAP && <Route path={adminRoute("/max-cap")} component={MaxCapForm} />}
        {TIERS_RANGE && <Route path={adminRoute("/tiers-range")} component={TiersListing} />}
        {TIERS_RANGE && <Route path={adminRoute("/edit-tiers-range/:id")} component={TiersForm} />}
        {RPC && <Route path={adminRoute("/config")} component={Config} />}
        {TEAM_MEMBER_SECTION && <Route path={adminRoute("/team-members")} component={TeamMemeberList} />}
        {RPC && <Route path={adminRoute("/rpc-url")} component={RPCListing} />}

        {RPC && <Route path={adminRoute("/add-rpc-url")} component={RPCForm} />}
        {RPC && <Route path={adminRoute("/edit-rpc-url/:id")} component={RPCForm} />}

        {WHITELIST_WALLETS_TAB && (
          <Route path={adminRoute("/white-list-wallets")} component={WhiteListWalletsListing} />
        )}

        {WHITELIST_WALLETS_TAB && (
          <Route path={adminRoute("/add-white-list-wallets")} component={WhiteListWalletsForm} />
        )}
        {WHITELIST_WALLETS_TAB && (
          <Route path={adminRoute("/edit-white-list-wallets/:id")} component={WhiteListWalletsForm} />
        )}

        {RECEIVER_WALLETS_TAB && <Route path={adminRoute("/receiver-wallets")} component={ReceiverWalletsListing} />}

        {RECEIVER_WALLETS_TAB && <Route path={adminRoute("/add-receiver-wallets")} component={ReceiverWalletsForm} />}
        {RECEIVER_WALLETS_TAB && (
          <Route path={adminRoute("/edit-receiver-wallets/:id")} component={ReceiverWalletsForm} />
        )}

        {ADMIN_LOGS && <Route path={adminRoute("/admin-logs")} component={AdminLogsList} />}
        {ROI_DASHBOARD && <Route path={adminRoute("/roi-dashboard")} component={ROIPredictionList} />}
        <Route path={adminRoute("/roi-details/:id")} component={ROIPredictionForm} />
        <Route path={adminRoute("/roi-create")} component={ROIPredictionForm} />

        <PrivateRoute exact path={adminRoute("/error")} component={ErrorPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  );
};

const RoutesHistory = withRouter(Routes);

const routing = function createRouting() {
  return (
    <>
      <NotificationContainer />
      <Router>
        <AppContainer>
          <ErrorBoundary>
            <RoutesHistory />
          </ErrorBoundary>
        </AppContainer>
      </Router>
    </>
  );
};
/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default routing;
