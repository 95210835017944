import React from "react";
import ConfirmDialog from "../../components/Base/ConfirmDialog";
import { updateSolanaStatus } from "../../request/config";
import { alertSuccess } from "../../store/actions/alert";
import { useDispatch } from "react-redux";

const StakingCachingForm = (props: any) => {
  const {
    isOpenEditPopupSwap,
    setIsOpenEditPopupSwap,
    disableButton,
    fetchFlags,
    swapFlag,
    swapFeatFlag,
    setSwapFeatFlag,
    displaySwapLabel,
    localSwapFlag,
  } = props;
  const dispatch = useDispatch();

  const handleSubmitPopup = async () => {
    const name = swapFlag?.name;
    try {
      const response = await updateSolanaStatus(
        { name, value: localSwapFlag },
        swapFlag?.id
      );

      fetchFlags();
      dispatch(alertSuccess("Successfull"));
      setIsOpenEditPopupSwap(false);
      setSwapFeatFlag(response.data.value);
    } catch (e) {
      console.log(e, "error");
    }
  };

  const handleCancel = () => {
    setIsOpenEditPopupSwap(false);
    setSwapFeatFlag(swapFeatFlag);
  };
  return (
    <ConfirmDialog
      title={
        displaySwapLabel ? "Enable Caching" : "Disable Caching"
      }
      open={isOpenEditPopupSwap}
      confirmLoading={disableButton}
      onConfirm={handleSubmitPopup}
      onCancel={handleCancel}
    >
      <div>Are you sure you want to {localSwapFlag ? "enable" : "disable"} Caching on Staking Pool?</div>
    </ConfirmDialog>
  );
};

export default StakingCachingForm;
