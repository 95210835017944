import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Tooltip,
  Button,
  CircularProgress,
} from "@material-ui/core";
import useComponentVisible from "../../../hooks/useComponentVisible";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import moment from "moment";

import useStyles from "./style";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import { deleteBlockedUser } from "../../../request/block-user";

type UserProps = {
  id: string;
  wallet_address: string;
  title?: string;
  updated_at?: number | null;
};

type UserRowProps = {
  user: UserProps;
  getBlockedUsersList: any;
};

const UserRecord: React.FC<UserRowProps> = (props: UserRowProps) => {
  const { user, getBlockedUsersList } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { ref } = useComponentVisible();

  const [deleteLoading, setDeleteLoading] = useState(false);

  const maskWalletAddress = (wallet: string) => {
    const preWalletLength = wallet.length;

    let r = wallet.slice(5, preWalletLength - 5);
    wallet = wallet.replace(r, "*****");

    return wallet;
  };

  const handleDeleteUser = async (id : number) => {
    if (!window.confirm("Do you want delete this item?")) {
      return false;
    }
    setDeleteLoading(true);
    const res = await deleteBlockedUser(id);

    if (res.status === 200) {
      dispatch(alertSuccess("Deleted Successfully"));
      await getBlockedUsersList();
    } else {
      dispatch(alertFailure("Delete failed"));
    }
    setDeleteLoading(false);
  };

  return (
    <>
      <TableRow ref={ref} className={classes.tableRow} key={user.id}>
        <TableCell className={classes.tableCell} align="left">
          <strong>{user.id}</strong>
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          <Tooltip
            title={<p style={{ fontSize: 15 }}>{user.wallet_address}</p>}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{maskWalletAddress(user.wallet_address)}</span>
              <CopyToClipboard
                onCopy={() => NotificationManager.success("Copied")}
                text={user.wallet_address}
              >
                <Button style={{ minWidth: "20px" }}>
                  <img
                    src="/images/icon-copy.svg"
                    alt=""
                    style={{ maxHeight: "18px" }}
                  />
                </Button>
              </CopyToClipboard>
            </div>
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          <strong>{user.title}</strong>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          {user.updated_at
            ? moment(user.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                "HH:mm DD/MM"
              )
            : "null"}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteUser(Number(user.id));
            }}
          >
            {deleteLoading ?  <CircularProgress size={25} /> : "Delete"}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserRecord;
