import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { deleteRPCUrl, getRPCUrls, rpcStatusUpdate } from "../../request/rpc";
import { RPCListResponse } from "./rpc.interface";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from "@material-ui/core";
import { Switch } from "antd";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { deleteWhitelistWallet, getWhiteListWallets } from "../../request/white-list-wallets";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import { UseCheckPermissions } from "../../utils/useCheckPermissions";
import NoPermisisons from "../../components/Base/NoPermissions";
import EmptyTable from "../../components/Base/Emptytable";

const WhiteListWalletsListing = () => {
  const [whiteListWallets, setWhiteListWallets] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const fetchData = async () => {
    try {
      const response = await getWhiteListWallets();
      if (response?.status === 200) {
        console.log("success", response);
        setWhiteListWallets(response?.data);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateStatus = async (rpcUrl: RPCListResponse) => {
    try {
      const isActive = rpcUrl?.isActive == 1 ? 0 : 1;
      const Id = rpcUrl?.id;
      const response = await rpcStatusUpdate(isActive, Id);
      fetchData();
    } catch (e) {}
  };

  const deleteWallet = async (whitelistWallet: any) => {
    try {
      if (!window.confirm("Are you sure?")) {
        return;
      }
      const response = await deleteWhitelistWallet(whitelistWallet?.id);
      if (response?.status === 200) {
        dispatch(alertSuccess("Deleted Successfully"));
        fetchData();
      } else {
        dispatch(alertFailure("Error While Deleting Wallet"));
      }
    } catch (e) {
      dispatch(alertFailure("Error While Deleting Wallet"));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // chain integration
  const networkName: any = {
    eth: "ETH",
    bsc: "BSC",
    polygon: "MATIC",
    arbitrum: "ARB",
    avalanche: "AVAX",
    solana: "SOL",
    coredao: "CORE",
    zksync: "zkSync",
    base: "BASE",
    linea: "LINEA",
    blast: "BLAST",
    bera: "BERA",
    sonic: "SONIC",
  };

  const isViewWhitelistWallets = UseCheckPermissions("VIEW_WHITE_LIST_WALLETS");
  const isEditWhitelistWallets = UseCheckPermissions("EDIT_WHITE_LIST_WALLETS");

  return (
    <DefaultLayout>
      {isViewWhitelistWallets ? (
        <>
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: "30px" }}
            onClick={() => {
              history.push("add-white-list-wallets");
            }}
            disabled={!isEditWhitelistWallets}
          >
            Add Wallet
          </Button>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell>Id</TableCell>*/}
                  <TableCell>Network</TableCell>
                  <TableCell>Wallet</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {whiteListWallets.length > 0 ? (
                  whiteListWallets?.map((whitelist: any) => (
                    <TableRow key={whitelist.id}>
                      {/*<TableCell>{rpcUrl.id}</TableCell>*/}
                      <TableCell>{networkName[whitelist.network]}</TableCell>
                      <TableCell>{whitelist.wallet}</TableCell>
                      <TableCell>
                        <Button
                          disabled={!isEditWhitelistWallets}
                          variant="contained"
                          color="primary"
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            history.push(`edit-white-list-wallets/${whitelist?.id}`);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          disabled={!isEditWhitelistWallets}
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            deleteWallet(whitelist);
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <EmptyTable title="White List" />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <NoPermisisons />
      )}
    </DefaultLayout>
  );
};

export default WhiteListWalletsListing;
