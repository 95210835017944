import React, { useEffect, useState } from "react";
import useStyles from "../style";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import { getTiers } from "../../../request/tier";
import { renderErrorCreatePool } from "../../../utils/validate";
import { BERA_CHAIN, NETWORK_AVAILABLE } from "../../../constants";

// chain integration
function AcceptCurrency(props: any) {
  const classes = useStyles();
  const { setValue, errors, control, watch, poolDetail, isEditDisabled } =
    props;
  const [isMounted, setIsMounted] = useState(false);
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && poolDetail.accept_currency) {
      setValue("acceptCurrency", poolDetail.accept_currency);
    }
  }, [poolDetail]);

  useEffect(() => {
    if (poolDetail && poolDetail.id) {
      getTiers(poolDetail.id).then((res) => {});
    }
  }, [poolDetail]);

  const isDeployed = !!poolDetail?.is_deploy;
  const networkAvailable = watch("networkAvailable");
  const acceptCurrency = watch("acceptCurrency");
  useEffect(() => {
    if (
      networkAvailable !== "tba" &&
      !poolDetail.id &&
      networkAvailable !== NETWORK_AVAILABLE.BASE &&
      networkAvailable !== NETWORK_AVAILABLE.ZKSYNC
    ) {
      setValue("acceptCurrency", "usdt");
    } else {
      if (poolDetail?.id && networkAvailable === NETWORK_AVAILABLE.ZKSYNC) {
        setValue("acceptCurrency", "usdc");
      }
      if (!poolDetail.id) {
        setValue("acceptCurrency", "tba");
      }
    }
  }, [networkAvailable]);

  useEffect(() => {
    if (
      networkAvailable !== "tba" &&
      (networkAvailable === NETWORK_AVAILABLE.BASE ||
        networkAvailable === NETWORK_AVAILABLE.ZKSYNC) &&
      !poolDetail.id
    ) {
      setValue("acceptCurrency", "usdc");
    }
  }, [networkAvailable, poolDetail]);

  useEffect(() => {
    if (
      networkAvailable !== "tba" &&
      networkAvailable === NETWORK_AVAILABLE.BLAST
    ) {
      setValue("acceptCurrency", "weth");
    }
  }, [networkAvailable, poolDetail]);

  useEffect(() => {
    if (
      networkAvailable !== "tba" &&
      networkAvailable === NETWORK_AVAILABLE.BERA
    ) {
      setValue("acceptCurrency", "honey");
    }
  }, [networkAvailable, poolDetail]);

  let nativeTokenLabel = "ETH";
  let usdtTokenLabel = "USDT";
  let showUsdc = true;
  let showWeth = false;
  let showUsdt = true;
  let showBusd = false;
  let showHny = false;
  switch (networkAvailable) {
    case "tba":
      nativeTokenLabel = "TBA";
      showUsdc = false;
      showBusd = false;
      break;
    case NETWORK_AVAILABLE.BSC:
      nativeTokenLabel = "BNB";
      showUsdc = true;
      showBusd = true;
      break;
    case NETWORK_AVAILABLE.POLYGON:
      nativeTokenLabel = "MATIC";
      usdtTokenLabel = "USDT";
      showUsdc = true;
      break;
    case NETWORK_AVAILABLE.AVALANCHE:
      nativeTokenLabel = "AVAX";
      usdtTokenLabel = "USDT";
      showUsdc = true;
      break;
    case NETWORK_AVAILABLE.ARBITRUM:
      nativeTokenLabel = "ETH";
      showUsdc = true;
      break;
    case NETWORK_AVAILABLE.BASE:
      nativeTokenLabel = "ETH";
      showUsdc = true;
      showUsdt = false;
      break;
    case NETWORK_AVAILABLE.ZKSYNC:
      nativeTokenLabel = "ETH";
      showUsdc = true;
      showUsdt = false;
      break;
    case NETWORK_AVAILABLE.OKX:
      nativeTokenLabel = "ETH";
      showUsdc = false;
      showUsdt = true;
      break;
    case NETWORK_AVAILABLE.LINEA:
      nativeTokenLabel = "ETH";
      showUsdc = true;
      showUsdt = true;
      break;
    case NETWORK_AVAILABLE.BLAST:
      nativeTokenLabel = "ETH";
      showUsdc = false;
      showUsdt = false;
      showWeth = true;
      break;
    case NETWORK_AVAILABLE.BERA:
      nativeTokenLabel = "ETH";
      showUsdc = false;
      showUsdt = false;
      showWeth = false;
      showHny = true;
      break;
    case NETWORK_AVAILABLE.SONIC:
      nativeTokenLabel = "ETH";
      showUsdc = true;
      showUsdt = true;
      break;
    default:
    // default is init value above
  }

  // console.log('userCurrentNetwork', isBscNetworks);

  return (
    <>
      <div className={classes.formControl}>
        <FormControl component="fieldset">
          <label className={classes.formControlLabel}>Accepted Currency</label>

          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={poolDetail?.accept_currency || "tba"}
            name="acceptCurrency"
            as={
              <RadioGroup row>
                {showBusd && (
                  <FormControlLabel
                    value="busd"
                    control={<Radio />}
                    label="BUSD"
                    disabled={isDeployed || isEditDisabled}
                  />
                )}
                {showUsdt && (
                  <FormControlLabel
                    value={networkAvailable != "tba" ? "usdt" : "tba"}
                    control={<Radio />}
                    label={networkAvailable != "tba" ? "USDT" : "TBA"}
                    disabled={isDeployed || isEditDisabled}
                  />
                )}
                {showUsdc && (
                  <FormControlLabel
                    value="usdc"
                    control={<Radio />}
                    label="USDC"
                    disabled={isDeployed || isEditDisabled}
                  />
                )}
                {showWeth && (
                  <FormControlLabel
                    value="weth"
                    control={<Radio />}
                    label="WETH"
                    disabled={isDeployed || isEditDisabled}
                  />
                )}
                {BERA_CHAIN && showHny && (
                  <FormControlLabel
                    value="honey"
                    control={<Radio />}
                    label="HONEY"
                    disabled={isDeployed || isEditDisabled}
                  />
                )}
                {/* <FormControlLabel
                  value={nativeTokenLabel.toLowerCase()}
                  control={<Radio />}
                  label={nativeTokenLabel}
                  disabled={isDeployed}
                /> */}
              </RadioGroup>
            }
          />
          <p className={classes.formErrorMessage}>
            {renderError(errors, "acceptCurrency")}
          </p>
        </FormControl>
      </div>
    </>
  );
}

export default AcceptCurrency;
