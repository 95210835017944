import axios from "axios";
import { apiRoute } from "../../utils";
import { BaseRequest } from "../Request";

interface GetRequestProps {
  url: string;
}

interface DELETERequestProps {
  url: string;
}

interface PostRequestProps {
  url: string;
  body: any;
}

interface PatchRequestProps {
  url: string;
  body: any;
}

export const fetchApiData = async ({ url }: GetRequestProps) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.get(url)) as any;
  return await response?.json();
};

export const deleteApiData = async ({ url }: DELETERequestProps) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const deleteApiDataWithBody = async ({ url, body }: PostRequestProps) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.delete(url, body)) as any;
  return await response?.json();
};

export const saveApiData = async ({ url, body }: PostRequestProps) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.post(url, body)) as any;
  return await response?.json();
};

export const updateApiData = async ({ url, body }: PatchRequestProps) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.patch(url, body)) as any;
  return await response?.json();
};

export const updateData = async ({ url, body }: PatchRequestProps) => {
  const baseRequest = new BaseRequest();
  const response = (await baseRequest.put(url, body)) as any;
  return await response?.json();
};

export const saveImageApi = async ({
  url,
  body,
  headers = {},
}: {
  url: any;
  body: any;
  headers?: any;
}) => {
  const urls = process.env.REACT_APP_API_BASE_URL + url;
  try {
    const response = await axios.post(urls, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        ...headers,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error saving data:", error);
    throw error;
  }
};

export const updateImageApi = async ({
  url,
  body,
  headers = {},
}: {
  url: any;
  body: any;
  headers?: any;
}) => {
  const urls = process.env.REACT_APP_API_BASE_URL + url;
  try {
    const response = await axios.put(urls, body, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        ...headers,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};


