import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { useCommonStyle } from "../../../../styles";
import { getFCFSJobUser, getFCFSUser } from "../../../../request/participants";
import useGetList from "../hooks/useGetList";
import Pagination from "@material-ui/lab/Pagination";
import useStylesTable from "./style_table";
import { etherscanRoute, isAdmin } from "../../../../utils";
import Link from "@material-ui/core/Link";
import useMapMaxBuyTier from "../hooks/useMapMaxBuyTier";
import BigNumber from "bignumber.js";
import { IMPORT_FCFS, POOL_IS_PRIVATE, CUSTOM_NETWORK } from "../../../../constants";
import { Button } from "@material-ui/core";
import { importWinner } from "../../../../request/pool";
import { useState } from "react";
import { alertFailure, alertSuccess } from "../../../../store/actions/alert";
import { useDispatch } from "react-redux";
import DownloadCsv from "../DownloadCsv";

function UserFCFS(props: any) {
  const commonStyle = useCommonStyle();
  const classesTable = useStylesTable();
  const [selectedFile, setSelectedFile] = useState(false);
  const [importResponse, setImportResponse] = useState("");
  const { poolDetail, isEditFCFSDisabled, isEditFCFSJOBDisabled } = props;
  const dispatch = useDispatch();
  const {
    rows,
    searchDelay,
    failure,
    loading,
    lastPage,
    currentPage,
    handlePaginationChange,
    status,
    setStatus,
    search,
  } = useGetList({ poolDetail, handleSearchFunction: getFCFSUser });

  const { maxBuyTiersMapping, minBuyTiersMapping } = useMapMaxBuyTier({
    poolDetail,
  });

  const STATUS = ["false", "true"];

  const handleSelectCSVFile = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleImportCSV = async () => {
    setImportResponse("");
    const res = await importWinner(poolDetail.id, selectedFile);

    dispatch((res.status === 200 ? alertSuccess : alertFailure)(res.message));
    search();
    if (res.status === 200) {
      setImportResponse(
        `Total: ${res.data?.total} --- Added: ${res.data?.added} --- Updated: ${res.data?.updated} --- Duplicated: ${res.data?.duplicated}`
      );
    }
  };

  const handleFCFS = async () => {
    const res = await getFCFSJobUser(poolDetail.id);
    dispatch((res.status === 200 ? alertSuccess : alertFailure)(res.message));
    search();
  };

  let role : any = localStorage.getItem("role");

  return (
    <div className={commonStyle.boxSearch}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          flexWrap: "wrap",
        }}
      >
        <div style={{ position: "relative", marginBottom: "1rem" }}>
          <input
            className={commonStyle.inputSearch}
            onChange={searchDelay}
            placeholder="Search"
            autoComplete="off"
          />
         

          <img
            src="/images/icon-search.svg"
            alt=""
            style={{ position: "absolute", right: "12px", bottom: "12px" }}
          />
        </div>
        <div style={{ marginBottom: "1rem",
  display:'inline-flex',
  gap:'5px',
  flexDirection:'column'
  

         }}>

          <input
            // className={styles.inputG}
            color="primary"
            type="file"
            accept=".csv, .xlsx"
            onChange={handleSelectCSVFile}
          />
          
          {poolDetail?.supported_token_types === "solana" ? (
              <DownloadCsv
                label="Download Sample File"
                filePath={
                  "https://chaingpt-launchpad.s3.dualstack.us-east-2.amazonaws.com/admin-csv-samples/solana_fcfs.csv"
                }
                fileName="solana_fcfs.csv"
              ></DownloadCsv>
            ) : (
              <DownloadCsv
              label="Download Sample File"
filePath={'https://chaingpt-launchpad.s3.dualstack.us-east-2.amazonaws.com/admin-csv-samples/import_pool_fcfs.csv'}
            fileName="import_pool_fcfs.csv"
            ></DownloadCsv>)}
        
        </div>
        <div style={{ marginBottom: "1rem" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleImportCSV}
            disabled={!selectedFile || isEditFCFSDisabled}
          >
            Import FCFS
          </Button>
        </div>
        {IMPORT_FCFS && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleFCFS}
            style={{ marginBottom: "1rem" }}
            disabled={isEditFCFSJOBDisabled}
          >
            Import FCFS Job
          </Button>
        )}
      </div>

      {(poolDetail.is_private === POOL_IS_PRIVATE.COMMUNITY ||
        poolDetail.is_private === POOL_IS_PRIVATE.EVENT) && (
        <Select
          name="status"
          value={status}
          style={{ marginLeft: 30 }}
          onChange={(e: any) => setStatus(e.target.value)}
        >
          <MenuItem value={-1}>All Status</MenuItem>
          {STATUS.map((value, index) => {
            return (
              <MenuItem key={index} value={index}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      )}

      <TableContainer
        component={Paper}
        className={`${commonStyle.tableScroll} ${classesTable.tableUserJoin}`}
      >
        <Table className={classesTable.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Wallet Address</TableCell>
              {poolDetail?.supported_token_types === "solana" && (
                <TableCell align="center">Solana Wallet Address</TableCell>
              )}
              {CUSTOM_NETWORK && poolDetail?.is_custom_network === 1 && (
                <TableCell align="center">Custom Wallet Address</TableCell>
              )}
              <TableCell align="center">FCFS Ticket</TableCell>
              <TableCell align="center">Snapshot Points</TableCell>
              <TableCell align="center">Level</TableCell>
              <TableCell align="center">Min Buy</TableCell>
              <TableCell align="center">Max Buy</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any, index: number) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  <Link
                    href={etherscanRoute(row.wallet_address, poolDetail)}
                    target={"_blank"}
                  >
                    {row.wallet_address}
                  </Link>
                </TableCell>
                {poolDetail?.supported_token_types === "solana" && (
                  <TableCell align="center">
                    <Link
                      href={etherscanRoute(row.solana_address, poolDetail)}
                      target={"_blank"}
                    >
                      {row.solana_address}
                    </Link>
                  </TableCell>
                )}
                {CUSTOM_NETWORK && poolDetail?.is_custom_network === 1 && (
                  <TableCell align="center">
                    <Link
                      href={`https://tonscan.org/address/${row?.custom_wallet_address}`}
                      target={"_blank"}
                    >
                      {row?.custom_wallet_address}
                    </Link>
                  </TableCell>
                )}

                <TableCell align="center" component="th" scope="row">
                  {row.fcfs_ticket || 0}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {row.total_points}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {row.level || 0}
                </TableCell>

                <TableCell align="center" component="th" scope="row">
                  0
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {/* {new BigNumber(maxBuyTiersMapping[row.level || 0])
                    .multipliedBy(row.fcfs_ticket || 0)
                    .toFixed(2)} */}
                {row?.total_allocation !== "0.000000000000000000" ? (new BigNumber(row.total_allocation)).toString() : new BigNumber(maxBuyTiersMapping[row.level || 0])
                    .multipliedBy(row.fcfs_ticket || 0)
                    .toFixed(2)}
                {/* {row?.total_allocation !== "0.000000000000000000" ? (new BigNumber(row.total_allocation)).toString() : new BigNumber(maxBuyTiersMapping[row.level || 0])
                    .multipliedBy(row.fcfs_ticket || 0)
                    .toFixed(2)} */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {failure && <p className={classesTable.errorMessage}>{failure}</p>}
        {!failure && (!rows || rows.length === 0) && !loading ? (
          <p className={classesTable.noDataMessage}>There is no data</p>
        ) : (
          <>
            {rows && lastPage > 1 && (
              <Pagination
                page={currentPage}
                className={classesTable.pagination}
                count={lastPage}
                onChange={handlePaginationChange}
              />
            )}
          </>
        )}
      </TableContainer>
    </div>
  );
}

export default UserFCFS;
