import React from "react";

interface DownloadCsvProps {
    filePath: string;
    fileName: string;
    label: string;
}

const DownloadCsv: React.FC<DownloadCsvProps> = ({ filePath, fileName, label }) => {
    return (
        <a
        style={{
            marginTop:'5px'
        }}
        href={filePath} download={fileName}>
            {label}
        </a>
    );
}

export default DownloadCsv;
