import React, { useEffect } from "react";
import useStyles from "../../style";
import { renderErrorCreatePool } from "../../../../utils/validate";

function LinkedInLink(props: any) {
  const classes = useStyles();
  const {
    register,
    setValue,
    errors,
    poolDetail,
    isEditDisabled
  } = props;
  const renderError = renderErrorCreatePool;

  const handleChangeLinkedInLink = (e: any) => {
    setValue("linkedIn_link", e.target.value);
  };

  useEffect(() => {
    setValue("linkedIn_link", poolDetail?.socialNetworkSetting?.linkedIn_link || "");
  }, [poolDetail, setValue]);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Linkedin Link</label>
        <input
          type="text"
          name="linkedIn_link"
          defaultValue={poolDetail?.socialNetworkSetting?.linkedIn_link}
          ref={register({
            // required: true
          })}
          onChange={handleChangeLinkedInLink}
          className={classes.formControlInput}
          disabled = {isEditDisabled}
          style = {{
            backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
          }}
        />
        <p className={classes.formErrorMessage}>
          {renderError(errors, "linkedIn_link")}
        </p>
      </div>
    </>
  );
}

export default LinkedInLink;
