import React, { useEffect, useState } from "react";
import { getSolanaTokenInfo, getTokenInfo } from "../../../utils/token";
import { CircularProgress, Tooltip, Button } from "@material-ui/core";
import useStyles from "../style";
import { debounce } from "lodash";
import { isGiveawayPool, renderErrorCreatePool } from "../../../utils/validate";
import { useDispatch, useSelector } from "react-redux";
import { CHAIN_ID_NAME_MAPPING, NETWORK_AVAILABLE } from "../../../constants";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import { getGiveawayPoolContract, getPoolContract } from "../../../services/web3";
import { updateDeploySuccess } from "../../../request/pool";

function TokenAddress(props: any) {
  const classes: any = useStyles();
  const dispatch = useDispatch();
  const { currentNetworkId } = useSelector((state: any) => state).userCurrentNetwork;
  const [loadingToken, setLoadingToken] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const { data: loginUser } = useSelector((state: any) => state.user);
  const { register, setValue, errors, watch, getValues, needValidate, poolDetail, token, setToken, isEditDisabled } =
    props;
  const renderError = renderErrorCreatePool;
  const networkAvailable = watch("networkAvailable");
  const relationship_type = watch("relationship_type");
  const isSolanaPool =
    watch("supported_token_types") === NETWORK_AVAILABLE.SOLANA || networkAvailable === NETWORK_AVAILABLE.SOLANA;
  const isDeployed = !!poolDetail?.is_deploy;

  useEffect(() => {
    if (poolDetail && poolDetail.token) {
      // First load when poolDetail change
      setValue("token", poolDetail.token, { shouldValidate: true });
      loadingTokenData(poolDetail.token);
    }
  }, [poolDetail]);

  useEffect(() => {
    if (poolDetail && networkAvailable) {
      // Auto load token when:
      // 1. Change network in Metamask
      // 2. Change select Network Available (Pool)
      const tokenAddressInputed = getValues("token");
      setValue("token", tokenAddressInputed, { shouldValidate: true });
      if (tokenAddressInputed) {
        loadingTokenData(tokenAddressInputed);
      }
    }
  }, [networkAvailable, currentNetworkId]);

  const loadingTokenData = async (tokenValue: string) => {
    try {
      setToken(null);
      setLoadingToken(true);

      const tokenAddress = tokenValue;
      const erc20Token = isSolanaPool
        ? await getSolanaTokenInfo(tokenAddress)
        : networkAvailable && networkAvailable !== "solana" && (await getTokenInfo(tokenAddress));
      if (erc20Token && !isSolanaPool) {
        const { name, symbol, decimals, address } = erc20Token;
        setLoadingToken(false);
        setToken(
          erc20Token
            ? {
                name,
                symbol,
                decimals,
                address,
              }
            : null
        );
      } else if (erc20Token && isSolanaPool) {
        const { tokenName, tokenSymbol, tokenDecimals } = erc20Token.data;
        setLoadingToken(false);
        setToken(
          erc20Token
            ? {
                tokenName,
                tokenSymbol,
                tokenDecimals,
                address: tokenValue,
                symbol: tokenSymbol,
                name: tokenName,
                decimals: tokenDecimals,
              }
            : null
        );
      }
    } catch (err) {
      setLoadingToken(false);
    }
  };

  const handleTokenGetInfo = debounce(async (e: any) => {
    try {
      await loadingTokenData(e.target.value);
    } catch (error) {}
  }, 500);

  const changeEditForm = async (value: boolean) => {
    setEditForm(value);
  };

  const changeTokenSale = async () => {
    try {
      if (!loginUser || !loginUser.wallet_address) {
        console.log("login user null");
        return;
      }

      if (!poolDetail || !poolDetail.network_available || !poolDetail.campaign_hash) {
        console.log("pool detail null");
        return;
      }

      if (poolDetail.network_available !== poolDetail.network_claim && !poolDetail.campaign_claim_hash) {
        console.log("claim pool is not deployed yet");
        return;
      }

      let poolAddress = poolDetail.campaign_hash;
      let poolNetwork = poolDetail.network_available;
      if (poolDetail.network_available !== poolDetail.network_claim) {
        poolAddress = poolDetail.campaign_claim_hash;
        poolNetwork = poolDetail.network_claim;
      }

      const newTokenAddress = getValues("token");
      const erc20Token = await getTokenInfo(newTokenAddress);
      var tokenName;
      var tokenSymbol;
      var tokenDecimals;
      var tokenAddress;
      if (erc20Token) {
        const { name, symbol, decimals, address } = erc20Token;
        tokenName = name;
        tokenSymbol = symbol;
        tokenAddress = address;
        tokenDecimals = decimals;
        setLoadingToken(false);
        setToken(
          erc20Token
            ? {
                name,
                symbol,
                decimals,
                address,
              }
            : null
        );
      }
      const contractInstance = isGiveawayPool(relationship_type)
        ? await getGiveawayPoolContract({
            networkAvailable: poolNetwork,
            poolHash: poolAddress,
            isClaimable: true,
            poolVersion: poolDetail?.pool_version,
          })
        : await getPoolContract({
            networkAvailable: poolNetwork,
            poolHash: poolAddress,
            isClaimable: true,
            poolVersion: poolDetail?.pool_version,
          });

      if (!contractInstance) {
        console.log("contract instance null");
        return;
      }
      let tx;

      if (isGiveawayPool(relationship_type)) {
        tx = await contractInstance.methods
          .changeGiveawayToken(newTokenAddress)
          .send({ from: loginUser.wallet_address });
      } else {
        tx = await contractInstance.methods.changeSaleToken(newTokenAddress).send({ from: loginUser.wallet_address });
      }
      if (!tx || !tx.events || !tx.events.TokenChanged) {
        console.log("tx failed", tx);
        dispatch(alertFailure(`Change Token sale blockchain error`));
        return;
      }

      // save to backend
      const updateData = {
        campaign_hash: poolDetail.campaign_hash,
        token_symbol: tokenSymbol,
        token_name: tokenName,
        token_decimals: tokenDecimals,
        token_address: tokenAddress,
        pool_version: poolDetail?.pool_version,
      };

      await updateDeploySuccess(updateData, poolDetail.id)
        .then(() => {
          dispatch(alertSuccess(`Change Token sale successfully`));
          window.location.reload();
        })
        .catch(() => {
          dispatch(alertFailure(`Change Token sale backend error`));
        });
    } catch (e: any) {
      dispatch(alertFailure(`Change Token sale error: ${e.message}`));
    }
  };

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Token address</label>
        {isDeployed && !editForm && !isSolanaPool && !isEditDisabled && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => changeEditForm(true)}
            style={{ marginLeft: 10, marginBottom: 10, float: "right" }}
          >
            Edit Token
          </Button>
        )}
        {isDeployed && editForm && !isSolanaPool && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => changeEditForm(false)}
            style={{ marginLeft: 10, marginBottom: 10, float: "right" }}
          >
            Cancel
          </Button>
        )}
        {isDeployed && editForm && !loadingToken && !isSolanaPool && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => changeTokenSale()}
            style={{ marginLeft: 10, marginBottom: 10, float: "right" }}
          >
            Change Token
          </Button>
        )}
        <div className={classes.formControlInputLoading}>
          <input
            type="text"
            name="token"
            ref={register({
              // required: true,
              validate: {
                invalidToken: async (val: string) => {
                  try {
                    if (!needValidate) {
                      if (val) {
                        const erc20Token = isSolanaPool ? await getSolanaTokenInfo(val) : await getTokenInfo(val);
                      }
                      return true;
                    }
                    const erc20Token = isSolanaPool ? await getSolanaTokenInfo(val) : await getTokenInfo(val);
                    return erc20Token;
                  } catch (err: any) {
                    return err.message;
                  }
                },
              },
            })}
            maxLength={255}
            onChange={handleTokenGetInfo}
            className={classes.formControlInput}
            disabled={(isDeployed && !editForm) || isEditDisabled}
            style={{
              backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
            }}
          />
          {loadingToken ? (
            <div className={classes.circularProgress}>
              <CircularProgress size={25} />
            </div>
          ) : errors["token"] &&
            (errors["token"].type === "tokenAlreadyUsed" || errors["token"].type === "invalidToken") ? (
            <img src="/images/icon_close.svg" className={classes.loadingTokenIcon} />
          ) : (
            token && <img src="/images/icon_check.svg" className={classes.loadingTokenIcon} />
          )}
        </div>
        <p className={`${classes.formErrorMessage}`}>{renderError(errors, "token")}</p>

        {errors?.token?.type && (
          <>
            <p className={`${classes.formErrorMessage}`}>You should check corresponding token with network.</p>
            <p className={`${classes.formErrorMessage}`}>
              Network Available Selected: <span style={{ textTransform: "uppercase" }}>{networkAvailable}</span>
            </p>
            <p className={`${classes.formErrorMessage}`}>
              Metamask User Network:{" "}
              <span>
                {CHAIN_ID_NAME_MAPPING[currentNetworkId]} ({currentNetworkId})
              </span>
            </p>
          </>
        )}
      </div>
      {token && (
        <div className={classes.tokenInfo}>
          <div className="tokenInfoBlock">
            <span className="tokenInfoLabel">Token</span>
            <div className="tokenInfoContent">
              <img src="/images/eth.svg" alt="erc20" />
              <Tooltip title={<p style={{ fontSize: 15 }}>{token.name}</p>}>
                <p className="tokenInfoText wordBreak">{`${token.name}`}</p>
              </Tooltip>
            </div>
          </div>
          <div className="tokenInfoBlock">
            <span className="tokenInfoLabel">Token Symbol</span>
            <div className="tokenInfoContent">
              <Tooltip title={<p style={{ fontSize: 15 }}>{token.symbol}</p>}>
                <p className="wordBreak">{`${token.symbol}`}</p>
              </Tooltip>
            </div>
          </div>
          <div className="tokenInfoBlock">
            <span className="tokenInfoLabel">Token Decimals</span>
            <div className="tokenInfoContent">{`${token.decimals}`}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default TokenAddress;
