import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import ImageUploaderWithForm from "../../components/Base/ImageUploader/ImageUploaderWithForm";
import { CardBody, Table, Card, Row, Col } from "reactstrap";
import DeleteModal from "../../components/Base/DeleteModal";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
// import ImageUploader from "../../components/PoolCreate/Components/ImageUploader";

// import * as apiHelper from "@/request/faq";
import {
  GET_TEAM_MEMBER_PAGE,
  SAVE_TEAM_MEMBER_PAGE,
  SORT_TEAM_MEMBER_PAGE,
  DELETE_TEAM_MEMBER_PAGE,
  UPDATE_TEAM_MEMBER_PAGE,
} from "../../request/config/Urls";
import {
  deleteApiData,
  fetchApiData,
  saveApiData,
  saveImageApi,
  updateData,
  updateImageApi,
} from "../../request/config/index";
import FormValidationError from "../../components/Base/FormValidationError";
import { updateFeaturePageSorting } from "../../request/faq";
import { Button } from "@material-ui/core";
import DefaultLayout from "../../components/Layout/DefaultLayout";
// import { updateFeaturePageSorting } from "@/request/faq";
// import { Button } from "@mui/material";

interface ITeamMemeberSection {
  id: number;
  name: string;
  bio: string;
  description: string;
  image_src: string;
  sort_id: number | null;
  created_at: string;
  updated_at: string;
}

const TeamMemeberList = () => {
  const [image, setImage]: any = useState("");
  const methods = useForm();
  const [teamMemberSectionsData, setTeamMemberSectionsData]: any = useState<
    ITeamMemeberSection[]
  >([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteTeamMemberSectionId, setDeleteTeamMemberSectionId] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetchApiData({ url: GET_TEAM_MEMBER_PAGE });
      setTeamMemberSectionsData(response.data);
    } catch (error: any) {
      console.error(
        `${error?.response?.data?.message || "Error fetching data:"}`
      );
      dispatch(
        alertFailure(
          `${error?.response?.data?.message || "Error fetching data:"}`
        )
      );
    }
  };
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;

  const onSubmit = async (data: any) => {
    // Create a new FormData object
    const formData = new FormData();

    // Append form data to FormData object
    for (const key in data) {
      formData.append(key, data[key]);
    }

    // Append the selected image file to FormData object
    if (image) {
      formData.append("image", image);
    }

    try {
      if (data.id) {
        // Update existing record
        // formData.append("sort_id", data.sort_id);
        const res = await updateImageApi({
          url: `${UPDATE_TEAM_MEMBER_PAGE}/${data.id}`,
          body: formData, // Pass FormData object as body
        });

        dispatch(alertSuccess("Team member updated successfully"));
        if (res) {
          reset();
          setImage(null);
          fetchData();
        }
      } else {
        formData.append(
          "sort_id",
          (teamMemberSectionsData.length + 1).toString()
        );
        // Save new record
        const res = await saveImageApi({
          url: SAVE_TEAM_MEMBER_PAGE,
          body: formData, // Pass FormData object as body
        });
        dispatch(alertSuccess("Team member added successfully"));
        if (res) {
          reset();
          setImage(null);
          fetchData();
        }
      }
    } catch (error: any) {
      dispatch(
        alertFailure(`Error: ${error?.message || "Unable to submit data"}`)
      );
    }
  };

  const handleFileSelect = (file: any) => {
    setImage(file);
    // setValue("file", "test")
  };

  async function handleDelete() {
    try {
      const url = `${DELETE_TEAM_MEMBER_PAGE}/${deleteTeamMemberSectionId}`;
      const res = await deleteApiData({ url });
      if (res) {
        dispatch(alertSuccess("Team Member deleted successfully"));
        fetchData();
      }
    } catch (err: any) {
      dispatch(
        alertFailure(`Error: ${err?.message || "Unable to delete Team Member"}`)
      );
    }
    setDeleteModalOpen(false);
  }

  function handleEdit(data: any) {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setImage(data.img_url);
    Object.keys(data).forEach((item: any) => {
      if (item !== "image") {
        setValue(item, data[item]);
      }
    });
    setImage(data.picture);
  }

  const updateSorting = async (updatedData: any) => {
    try {
      const response = await updateFeaturePageSorting(SORT_TEAM_MEMBER_PAGE, {
        feature_section: updatedData,
      });
      if (response.status === 200) {
        dispatch(alertSuccess("Updated Successfully"));
      } else {
        dispatch(alertFailure(response?.message));
      }
    } catch (error: any) {
      console.error(`${error?.message || "Error Updating"}`);
      dispatch(alertFailure(`${error?.message || "Error Updating"}`));
    }
  };

  const onDragEnd = (result: any) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items: ITeamMemeberSection[] = Array.from(teamMemberSectionsData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      id: item?.id,
      sort_id: index + 1,
    }));
    updateSorting(data);
    setTeamMemberSectionsData(items);
  };

  const tableHeader = [
    "#",
    "Profile Img",
    "Name",
    "Designation",
    "Linkedin",
    "Description",
    "Actions",
  ];

  return (
    <DefaultLayout>

    
    <div style={{
        margin:'50px 0px'
    }}>
      <Row>
        <Col xs={12}>
          <CardBody className="p-0">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <input type="text" hidden name="id" ref={register({})} />
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-firstname-input"
                        className="form-label"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input"
                        placeholder="Enter Name"
                        name="name"
                        ref={register({ required: "Name is Required" })}
                      />
                      <FormValidationError
                        errorMessage={errors?.name?.message}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-lastname-input"
                        className="form-label"
                      >
                        Designation
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="basicpill-lastname-input"
                        placeholder="Your Designation"
                        name="designation"
                        ref={register({
                          required: "The designation feild is Required",
                        })}
                      />
                      <FormValidationError
                        errorMessage={errors?.designation?.message}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-address-input"
                        className="form-label"
                      >
                        Description
                      </label>
                      <textarea
                        id="basicpill-address-input"
                        className="form-control"
                        placeholder="Write about something"
                        rows={2}
                        name="description"
                        ref={register({
                        //   required: "The Bio feild is Required",
                        })}
                      ></textarea>
                      <FormValidationError
                        errorMessage={errors?.description?.message}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3">
                    <div className="mb-3">
                      <label
                        htmlFor="basicpill-lastname-input"
                        className="form-label"
                      >
                        Linkiden Url
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="basicpill-lastname-input"
                        placeholder="http://linkiden.in"
                        name="linkedin"
                        ref={register({})}
                      />
                      <FormValidationError
                        errorMessage={errors?.linkedin?.message}
                      />
                    </div>
                  </div>
                </div>
                <Row>
                  <Col className="col-12">
                    <label className="form-label">Profile Image</label>
                    {/* <ImageUploader
                      onFileSelect={handleFileSelect}
                      width="50%"
                      initialSrc={image}
                      poolId={(Math.random() * 1000) as unknown as string}
                    /> */}

                    <ImageUploaderWithForm
                      field="file"
                      onFileSelect={handleFileSelect}
                      initialSrc={image}
                    ></ImageUploaderWithForm>
                  </Col>
                </Row>
                <div>
                  <Button
                    type="submit"
                    className="mt-3"
                    color="primary"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </FormProvider>
          </CardBody>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={12}>
          <div>
            <label className="form-label">Team Members Table</label>
          </div>
          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      {tableHeader.map((item, index) => {
                        return <th key={index + item}>{item}</th>;
                      })}
                    </tr>
                  </thead>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {teamMemberSectionsData?.map(
                            (item: any, index: any) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <th scope="row">{item?.id}</th>
                                    <td>
                                      <img
                                        width={30}
                                        height={30}
                                        src={item?.picture}
                                        alt=""
                                      />
                                    </td>
                                    <td>{item?.name}</td>
                                    <td>{item?.designation}</td>
                                    <td
                                      style={{
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {item?.linkedin}
                                    </td>
                                    {/* <td>{item?.background_color}</td> */}
                                    <td title={item.description}>
                                      {item?.description?.length > 20
                                        ? item?.description.slice(0, 20) + "..."
                                        : item?.description}
                                    </td>
                                    <td>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => handleEdit(item)}
                                      >
                                        Edit
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                          setDeleteModalOpen(true);
                                          setDeleteTeamMemberSectionId(
                                            item?.id
                                          );
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            )
                          )}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        title="Team Member Section"
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        onDeleteClick={handleDelete}
      />
    </div>
    </DefaultLayout>
  );
};

export default TeamMemeberList;
