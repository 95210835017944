import moment from "moment";
import { BaseRequest } from "./Request";
import { apiRoute } from "../utils";
import FileDownload from "js-file-download";
import { EXPORT_USER_TYPE } from "../constants";
const queryString = require("query-string");

export const getBlockUserList = async (queryParams: any) => {
  console.log("🚀 ~ getBlockUserList ~ queryParams:", queryParams)
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/blocked-users`);
  url += "?" + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const exportBlockUserList = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/users/download?type=${EXPORT_USER_TYPE.USER_LIST}`);
  const fileData = (await baseRequest.postDownload(url, {})) as any;
  FileDownload(
    fileData,
    `launchpad_users_${moment().format("DD_MM_YYYY")}.csv`
  );
};

export const setBlockUser = async (param: {
  walletAddress: string;
  campaign_id: number;
}) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/blocked-user`;

  const response = (await baseRequest.post(url, {
    wallet_address: param.walletAddress,
    campaign_id: param.campaign_id,
  })) as any;
  const resObject = await response.json();

  return resObject;
};

export const importBlockUsers = async (file: any) => {
  const baseRequest = new BaseRequest();
  var form_data = new FormData();
  form_data.append("users", file);
  let url = apiRoute(`/import-blocked-users`);
  const response = (await baseRequest.postImage(url, form_data)) as any;
  const resObject = await response.json();
  return resObject;
};

export const deleteBlockedUser = async (userId: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/blocked-user/${userId}`);

  const response = await baseRequest.delete(url, {}) as any;
  const resObject = await response.json();

  return resObject;
}