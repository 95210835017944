import { BaseRequest } from "./Request";
import { apiRoute } from "../utils";
const queryString = require("query-string");

export const getRoiPredictionList = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/roi-predictions`);
  url += "?" + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createRoiPrediction = async (params: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/roi-predictions`);
  const response = (await baseRequest.post(url, params)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getRoiPredictionById = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/roi-predictions/${id}`);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateRoiPredictionById = async (
  id: number | string,
  data: any
) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/roi-predictions/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteRoiPredictionById = async (id: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/roi-predictions/${id}`);
  const response = (await baseRequest.delete(url, { id })) as any;
  const resObject = await response.json();

  return resObject;
};
