export const TRANSACTION_ERROR = 'Transaction failed. Please check blockchain to know more error.';
export const DEFAULT_LIMIT = 10;
export const API_URL_PREFIX = 'admin';
export const ADMIN_URL_PREFIX = 'dashboard';
export const IMAGE_URL_PREFIX = 'image';
export const MAX_BUY_CAMPAIGN = 1000;
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const TIERS_LABEL = ['-', 'Bronze', 'Silver', 'Gold', 'Diamond'];
export const POOL_DURATION_LABEL = ['3 Years', '2 Years', '365 Days', '180 Days', '90 Days', '45 Days'];
export const BONUS_LABEL_WITH_TIER = ['Export All', 'Export users with bonus', 'Export users without bonus'];

export const TIERS = {
  DOVE: 1,
  HAWK: 2,
  EAGLE: 3,
  PHOENIX: 4,
};

export const POOL_VERSIONS = {
  VERSION_ONE: 'v1',
  VERSION_TWO: 'v2',
};

export const REFUND_TIME_FOR_TIER = {
  [TIERS.DOVE]: 3,
  [TIERS.HAWK]: 5,
  [TIERS.EAGLE]: 12,
  [TIERS.PHOENIX]: 24,
};

export const months = [
  { value: 0, label: 'All Month' },
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];
export const ONBOARD_STATUS = {
  ALL: 0,
  SUCCESSFULL: 1,
  PENDING: 2,
};
export const onboardStatus = [
  { value: ONBOARD_STATUS.ALL, label: 'All Onboarding Statuses' },
  { value: ONBOARD_STATUS.SUCCESSFULL, label: 'Successful' },
  { value: ONBOARD_STATUS.PENDING, label: 'Pending' },
];

// chain integration
export const ACCEPT_CURRENCY = {
  ETH: 'eth',
  BUSD: 'busd',
  USDT: 'usdt',
  USDC: 'usdc',
  WETH: 'weth',
  HNY: 'honey',
};
export const BUY_TYPE = {
  WHITELIST_LOTTERY: 'whitelist',
  FCFS: 'fcfs',
};
export const POOL_TYPE = {
  SWAP: 'swap',
  CLAIMABLE: 'claimable',
};
export const TOKEN_TYPE = {
  BEP20: 'bep20',
  ERC20: 'erc20',
};
export const CLAIM_TYPE = {
  CLAIM_ON_LAUNCHPAD: 'claim-on-launchpad',
  AIRDROP_TO_PARTICIPANTS_WALLETS: 'airdrop-to-participants-wallet',
  CLAIM_A_PART_OF_TOKENS_ON_LAUNCHPAD: 'claim-a-part-of-tokens-on-launchpad',
  CLAIM_ON_THE_PROJECT_WEBSITE: 'claim-on-the-project-website',
};
export const NETWORK_AVAILABLE = {
  ETH: 'eth',
  BSC: 'bsc',
  POLYGON: 'polygon',
  AVALANCHE: 'avalanche',
  ARBITRUM: 'arbitrum',
  SOLANA: 'solana',
  BASE: 'base',
  DAO: 'coredao',
  OKX: 'xlayer',
  ZKSYNC: 'zksync',
  LINEA: 'linea',
  BLAST: 'blast',
  BERA: 'bera',
  SONIC: 'sonic'
};
export const PUBLIC_WINNER_STATUS = {
  PUBLIC: 1,
  PRIVATE: 0,
};
export const POOL_IS_PRIVATE = {
  PUBLIC: 0,
  PRIVATE: 1,
  SEED: 2,
  COMMUNITY: 3,
  EVENT: 4,
};
export const PICK_WINNER_RULE = {
  RULE_LUCKY_AND_WEIGHT: 'rule-lucky-and-weight',
};

export const USDT_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_ETH_USDT_ADDRESS;
export const USDC_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_ETH_USDC_ADDRESS;

export const USDT_BSC_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_BSC_USDT_ADDRESS;
export const USDC_BSC_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_BSC_USDC_ADDRESS;
export const BUSD_BSC_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_BSC_BUSD_ADDRESS;

export const USDT_POLYGON_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_POLYGON_USDT_ADDRESS;
export const USDC_POLYGON_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_POLYGON_USDC_ADDRESS;

export const USDT_AVALANCHE_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_AVALANCHE_USDT_ADDRESS;
export const USDC_AVALANCHE_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_AVALANCHE_USDC_ADDRESS;

export const USDT_ARBITRUM_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_ARBITRUM_USDT_ADDRESS;
export const USDC_ARBITRUM_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_ARBITRUM_USDC_ADDRESS;

export const USDT_BASE_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_BASE_USDT_ADDRESS;
export const USDC_BASE_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_BASE_USDC_ADDRESS;

export const USDT_DAO_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_DAO_USDT_ADDRESS;
export const USDC_DAO_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_DAO_USDC_ADDRESS;

export const USDT_OKX_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_OKX_USDT_ADDRESS;
export const USDC_OKX_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_OKX_USDC_ADDRESS;
export const USDT_ZKSYNC_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_ZKSYNC_USDT_ADDRESS;
export const USDC_ZKSYNC_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_ZKSYNC_USDC_ADDRESS;
export const USDT_LINEA_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_LINEA_USDT_ADDRESS;
export const USDC_LINEA_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_LINEA_USDC_ADDRESS;
export const HNY_BERA_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_BERA_HNY_ADDRESS;

export const USDT_SONIC_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_SONIC_USDT_ADDRESS;
export const USDC_SONIC_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_SONIC_USDC_ADDRESS;

export const WETH_BLAST_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_BLAST_WETH_ADDRESS;

export const ETHERSCAN_URL = process.env.REACT_APP_ETHERSCAN_BASE_URL || '';
export const BCSSCAN_URL = process.env.REACT_APP_BSCSCAN_BASE_URL || '';

export const ETH_CHAIN_ID = process.env.REACT_APP_ETH_NETWORK_ID as string;
export const BSC_CHAIN_ID = process.env.REACT_APP_BSC_NETWORK_ID as string;

export const POLYGON_CHAIN_ID = process.env.REACT_APP_POLYGON_NETWORK_ID as string;
export const AVALANCHE_CHAIN_ID = process.env.REACT_APP_AVALANCHE_NETWORK_ID as string;
export const ARBITRUM_CHAIN_ID = process.env.REACT_APP_ARBITRUM_NETWORK_ID as string;
export const BASE_CHAIN_ID = process.env.REACT_APP_BASE_NETWORK_ID as string;
export const DAO_CHAIN_ID = process.env.REACT_APP_DAO_NETWORK_ID as string;
export const OKX_CHAIN_ID = process.env.REACT_APP_OKX_NETWORK_ID as string;
export const ZKSYNC_CHAIN_ID = process.env.REACT_APP_ZKSYNC_NETWORK_ID as string;
export const LINEA_CHAIN_ID = process.env.REACT_APP_LINEA_NETWORK_ID as string;
export const BLAST_CHAIN_ID = process.env.REACT_APP_BLAST_NETWORK_ID as string;
export const BERA_CHAIN_ID = process.env.REACT_APP_BERA_NETWORK_ID as string;
export const SONIC_CHAIN_ID = process.env.REACT_APP_SONIC_NETWORK_ID as string;

export const NETWORK_ETH_NAME = process.env.REACT_APP_ETH_NETWORK_NAME;
export const NETWORK_BSC_NAME = process.env.REACT_APP_BSC_NETWORK_NAME;
export const NETWORK_POLYGON_NAME = process.env.REACT_APP_POLYGON_NETWORK_NAME;
export const NETWORK_BASE_NAME = process.env.REACT_APP_BASE_NETWORK_NAME;
export const NETWORK_DAO_NAME = process.env.REACT_APP_DAO_NETWORK_NAME;
export const NETWORK_OKX_NAME = process.env.REACT_APP_OKX_NETWORK_NAME;
export const NETWORK_ZKSYNC_NAME = process.env.REACT_APP_ZKSYNC_NETWORK_NAME;
export const NETWORK_LINEA_NAME = process.env.REACT_APP_LINEA_NETWORK_NAME;
export const NETWORK_BLAST_NAME = process.env.REACT_APP_BLAST_NETWORK_NAME;
export const NETWORK_BERA_NAME = process.env.REACT_APP_BERA_NETWORK_NAME;
export const NETWORK_SONIC_NAME = process.env.REACT_APP_SONIC_NETWORK_NAME;
export const NATIVE_TOKEN_ADDRESS = '0x0000000000000000000000000000000000000000';

export const MAPPING_CHAINNAME_CHAINID: any = {
  [NETWORK_AVAILABLE.ETH]: ETH_CHAIN_ID,
  [NETWORK_AVAILABLE.BSC]: BSC_CHAIN_ID,
  [NETWORK_AVAILABLE.POLYGON]: POLYGON_CHAIN_ID,
  [NETWORK_AVAILABLE.AVALANCHE]: AVALANCHE_CHAIN_ID,
  [NETWORK_AVAILABLE.ARBITRUM]: ARBITRUM_CHAIN_ID,
  [NETWORK_AVAILABLE.BASE]: BASE_CHAIN_ID,
  [NETWORK_AVAILABLE.DAO]: DAO_CHAIN_ID,
  [NETWORK_AVAILABLE.OKX]: OKX_CHAIN_ID,
  [NETWORK_AVAILABLE.ZKSYNC]: ZKSYNC_CHAIN_ID,
  [NETWORK_AVAILABLE.LINEA]: LINEA_CHAIN_ID,
  [NETWORK_AVAILABLE.BLAST]: BLAST_CHAIN_ID,
  [NETWORK_AVAILABLE.BERA]: BERA_CHAIN_ID,
  [NETWORK_AVAILABLE.SONIC]: SONIC_CHAIN_ID,
};

export const MAPPING_CURRENCY_ADDRESS: any = {
  eth: {
    eth: NATIVE_TOKEN_ADDRESS,
    native: NATIVE_TOKEN_ADDRESS,
    usdt: USDT_ADDRESS,
    usdc: USDC_ADDRESS,
  },
  bsc: {
    eth: NATIVE_TOKEN_ADDRESS, // eth for native token
    native: NATIVE_TOKEN_ADDRESS,
    busd: BUSD_BSC_ADDRESS,
    usdt: USDT_BSC_ADDRESS,
    usdc: USDC_BSC_ADDRESS,
  },
  polygon: {
    eth: NATIVE_TOKEN_ADDRESS, // eth for native token
    native: NATIVE_TOKEN_ADDRESS,
    usdt: USDT_POLYGON_ADDRESS,
    usdc: USDC_POLYGON_ADDRESS,
  },
  avalanche: {
    eth: NATIVE_TOKEN_ADDRESS, // eth for native token
    native: NATIVE_TOKEN_ADDRESS,
    usdt: USDT_AVALANCHE_ADDRESS,
    usdc: USDC_AVALANCHE_ADDRESS,
  },
  arbitrum: {
    eth: NATIVE_TOKEN_ADDRESS, // eth for native token
    native: NATIVE_TOKEN_ADDRESS,
    usdt: USDT_ARBITRUM_ADDRESS,
    usdc: USDC_ARBITRUM_ADDRESS,
  },
  base: {
    eth: NATIVE_TOKEN_ADDRESS, // eth for native token
    native: NATIVE_TOKEN_ADDRESS,
    usdt: USDT_BASE_ADDRESS,
    usdc: USDC_BASE_ADDRESS,
  },
  coredao: {
    eth: NATIVE_TOKEN_ADDRESS, // eth for native token
    native: NATIVE_TOKEN_ADDRESS,
    usdt: USDT_DAO_ADDRESS,
    usdc: USDC_DAO_ADDRESS,
  },
  xlayer: {
    eth: NATIVE_TOKEN_ADDRESS, // eth for native token
    native: NATIVE_TOKEN_ADDRESS,
    usdt: USDT_OKX_ADDRESS,
    usdc: USDC_OKX_ADDRESS,
  },
  zksync: {
    eth: NATIVE_TOKEN_ADDRESS, // eth for native token
    native: NATIVE_TOKEN_ADDRESS,
    usdc: USDC_ZKSYNC_ADDRESS,
  },
  linea: {
    eth: NATIVE_TOKEN_ADDRESS, // eth for native token
    native: NATIVE_TOKEN_ADDRESS,
    usdt: USDT_LINEA_ADDRESS,
    usdc: USDC_LINEA_ADDRESS,
  },
  blast: {
    eth: NATIVE_TOKEN_ADDRESS, // eth for native token
    native: NATIVE_TOKEN_ADDRESS,
    weth: WETH_BLAST_ADDRESS,
  },
  bera: {
    eth: NATIVE_TOKEN_ADDRESS,
    native: NATIVE_TOKEN_ADDRESS,
    honey: HNY_BERA_ADDRESS,
  },
  sonic: {
    eth: NATIVE_TOKEN_ADDRESS,
    native: NATIVE_TOKEN_ADDRESS,
    usdt: USDT_SONIC_ADDRESS,
    usdc: USDC_SONIC_ADDRESS,
  },
};

export const APP_NETWORK_NAMES = {
  [ETH_CHAIN_ID]: NETWORK_ETH_NAME,
  [BSC_CHAIN_ID]: NETWORK_BSC_NAME,
  [BERA_CHAIN_ID]: NETWORK_BERA_NAME,
};
export const ACCEPT_NETWORKS = {
  ETH_CHAIN_ID: process.env.REACT_APP_ETH_NETWORK_ID,
  BSC_CHAIN_ID: process.env.REACT_APP_BSC_NETWORK_ID,
  POLYGON_CHAIN_ID: process.env.REACT_APP_POLYGON_NETWORK_ID,
  AVALANCHE_CHAIN_ID: process.env.REACT_APP_AVALANCHE_NETWORK_ID,
  ARBITRUM_CHAIN_ID: process.env.REACT_APP_ARBITRUM_NETWORK_ID,
  BASE_CHAIN_ID: process.env.REACT_APP_BASE_NETWORK_ID,
  DAO_CHAIN_ID: process.env.REACT_APP_DAO_NETWORK_ID,
  OKX_CHAIN_ID: process.env.REACT_APP_OKX_NETWORK_ID,
  ZKSYNC_CHAIN_ID: process.env.REACT_APP_ZKSYNC_NETWORK_ID,
  LINEA_CHAIN_ID: process.env.REACT_APP_LINEA_NETWORK_ID,
  BLAST_CHAIN_ID: process.env.REACT_APP_BLAST_NETWORK_ID,
  BERA_CHAIN_ID: process.env.REACT_APP_BERA_NETWORK_ID,
  SONIC_CHAIN_ID: process.env.REACT_APP_SONIC_NETWORK_ID,
};

export const CHAIN_IDS = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  KOVAN: 42,
  BSC_TESTNET: 97,
  BSC_MAINNET: 56,
  POLYGON_TESTNET: 80001,
  POLYGON: 137,
  ARBITRUM: 42161,
  ARBITRUM_TESTNET: 421613,
  BASE: 8453,
  BASE_TESTNET: 84532,
  DAO: 1116,
  DAO_TESTNET: 1115,
  OKX: 196,
  OKX_TESTNET: 195,
  ZKSYNC: 324,
  ZKSYNC_TESTNET: 300,
  LINEA: 59144,
  LINEA_TESTNET: 59141,
  BLAST: 81457,
  BLAST_TESTNET: 168587773,
  BERA_TESTNET: 80084,
  SONIC: 146,
  SONIC_TESTNET: 57054,
};
export const CHAIN_ID_NAME_MAPPING: any = {
  '1': 'Mainnet',
  '56': 'BSC Mainnet',
  '137': 'Polygon Mainnet',
  '43114': 'Avalanche Network',
  '42161': 'Arbitrum Mainnet',
  '8453': 'Base Mainnet',
  '1116': 'Core Blockchain Mainnet',
  '324': 'zkSync Mainnet',
  '196': 'X Layer Mainnet',
  '59144': 'Linea Mainnet',
  '81457': 'Blast Mainnet',
  '146': 'Sonic Mainnet',
  '3': 'Ropsten',
  '4': 'Rinkeby',
  '5': 'Goerli',
  '42': 'Kovan',
  '97': 'BSC Testnet',
  '80001': 'Polygon Testnet',
  '43113': 'Avalanche FUJI C-Chain',
  '421613': 'Arbitrum Goerli Testnet',
  '84532': 'Base Sepolia Testnet',
  '1115': 'Core Blockchain Testnet',
  '195': 'X Layer Testnet',
  '300': 'zkSync Sepolia Testnet',
  '59141': 'Linea Sepolia Testnet',
  '168587773': 'Blast Sepolia Testnet',
  '80084': 'Berachain bArtio',
  '57054': 'Sonic Testnet',
};

export const CHAIN_NAME_ID_MAPPING: any = {
  '1': 'eth',
  '11155111': 'eth',
  '97': 'bsc',
  '56': 'bsc',
  '137': 'polygon',
  '80002': 'polygon',
  '43114': 'avalanche',
  '43113': 'avalanche',
  '42161': 'arbitrum',
  '421614': 'arbitrum',
  '8453': 'base',
  '84532': 'base',
  '1116': 'coredao',
  '1115': 'coredao',
  '196': 'xlayer',
  '195': 'xlayer',
  '324': 'zksync',
  '300': 'zksync',
  '59144': 'linea',
  '59141': 'linea',
  '81457': 'blast',
  '168587773': 'blast',
  '80084': 'bera',
  '80094': 'bera',
  '146': 'sonic',
  '57054': 'sonic',
};

export const CHAIN_ID_NAME_MAPPING_NETWORK_CHECK: any = {
  '11155111': 'eth',
  '3': 'eth',
  '4': 'eth',
  '5': 'eth',
  '42': 'eth',
  '97': 'bsc',
  '56': 'bsc',
  '137': 'polygon',
  '80002': 'polygon',
  '43114': 'avalanche',
  '43113': 'avalanche',
  '42161': 'arbitrum',
  '421613': 'arbitrum',
  '8453': 'base',
  '84532': 'base',
  '1116': 'coredao',
  '1115': 'coredao',
  '196': 'xlayer',
  '195': 'xlayer',
  '324': 'zksync',
  '300': 'zksync',
  '59144': 'linea',
  '59141': 'linea',
  '81457': 'blast',
  '168587773': 'blast',
  '80084': 'bera',
  '80094': 'bera',
  '57054': 'sonic',
  '146': 'sonic',
};
export const ETH_NETWORK_ACCEPT_CHAINS: any = {
  '1': 'Mainnet',
  '3': 'Ropsten',
  '4': 'Rinkeby',
  '5': 'Goerli',
  '42': 'Kovan',
  '11155111': 'Sepolia',
  '80084': 'bera',
  '80094': 'bera',
};
export const BSC_NETWORK_ACCEPT_CHAINS: any = {
  '97': 'BSC Testnet',
  '56': 'BSC Mainnet',
};

export const POLYGON_NETWORK_ACCEPT_CHAINS: any = {
  '80002': 'Polygon Testnet',
  '137': 'Polygon Mainnet',
};

export const AVALANCE_NETWORK_ACCEPT_CHAINS: any = {
  '43114': 'Avalanche Network',
  '43113': 'Avalanche FUJI C-Chain',
};
export const ARBITRUM_NETWORK_ACCEPT_CHAINS: any = {
  '42161': 'Arbitrum Mainnet',
  '421613': 'Arbitrum Goerli Testnet',
  '421614': 'Arbitrum Sepolia Testnet',
};

export const BASE_NETWORK_ACCEPT_CHAINS: any = {
  '8453': 'Base Mainnet',
  '84532': 'Base Sepolia Testnet',
};

export const DAO_NETWORK_ACCEPT_CHAINS: any = {
  '1116': 'Core Blockchain Mainnet',
  '1115': 'Core Blockchain Testnet',
};

export const OKX_NETWORK_ACCEPT_CHAINS: any = {
  '196': 'X Layer Mainnet',
  '195': 'X Layer Testnet',
};

export const ZKSYNC_NETWORK_ACCEPT_CHAINS: any = {
  '324': 'zkSync Mainnet',
  '300': 'zkSync Sepolia Testnet',
};

export const LINEA_NETWORK_ACCEPT_CHAINS: any = {
  '59144': 'Linea Mainnet',
  '59141': 'Linea Sepolia Testnet',
};

export const BLAST_NETWORK_ACCEPT_CHAINS: any = {
  '81457': 'Blast Mainnet',
  '168587773': 'Blast Sepolia Testnet',
};
export const BERA_NETWORK_ACCEPT_CHAINS: any = {
  '80084': 'Berachain bArtio',
  '80094': 'Berachain Mainnet',
};

export const SONIC_NETWORK_ACCEPT_CHAINS: any = {
  '146': 'Sonic Mainnet',
  '57054': 'Sonic Blaze Testnet',
};

export const ETHERSCAN_BASE_URL: any = {
  '1': 'https://etherscan.io/address',
  '4': 'https://rinkeby.etherscan.io/address',
  '5': 'https://goerli.etherscan.io/address',
  '56': 'https://bscscan.com/address',
  '97': 'https://testnet.bscscan.com/address',
  '137': 'https://polygonscan.com/address/',
  '80001': 'https://mumbai.polygonscan.com/address/',
  '42161': 'https://arbiscan.io/address/',
  '421613': 'https://goerli.arbiscan.io/address/',
  '8453': 'https://basescan.org/address',
  '84532': 'https://sepolia.basescan.org/address',
  '1116': 'https://scan.coredao.org/address',
  '1115': 'https://scan.test.btcs.network/address',
  '196': 'https://www.okx.com/web3/explorer/xlayer/address/',
  '195': 'https://www.okx.com/web3/explorer/xlayer-test/address',
  sol: 'https://explorer.solana.com/address/',
  '324': 'https://explorer.zksync.io/address/',
  '300': 'https://sepolia.explorer.zksync.io/address/',
  '59144': 'https://lineascan.build/address/',
  '59141': 'https://sepolia.lineascan.build/address/',
  '81457': 'https://lineascan.build/address/',
  '168587773': 'https://blastscan.io/',
  '80084': 'https://bartio.beratrail.io/address/',
  '80094': 'https://berascan.com/address',
  '146': 'https://sonicscan.org/address/',
  '57054': 'https://testnet.sonicscan.org/address/',
};

export const EXPORT_USER_TYPE: any = {
  USER_LIST: 'USER_LIST',
  USER_PARTICIPANT: 'USER_PARTICIPANT',
  USER_WINNER: 'USER_WINNER',
  KYC_USER_LIST: 'KYC_USER',
};

export const CONTRACT_VERSIONS: any = {
  eth: 'v2',
  bsc: 'v2',
  polygon: 'v2',
  avalanche: 'v2',
  arbitrum: 'v2',
  solana: 'v2',
  base: 'v2',
  coredao: 'v2',
  xlayer: 'v2',
  zksync: 'v2',
  linea: 'v2',
  blast: 'v2',
  bera: 'v2',
  sonic: 'v2',
};

export const GIVEAWAY_POOL_VERSIONS: any = {
  V1: 'giveaway_v1',
  V2: 'giveaway_v2',
};

export const JOB_ID: any = {
  progressBar: 'RecalculateUsersTiers-job',
};

export const RPC = true;
export const SOLANA_MULTI_SIG = true;
export const DELETE_ALL_PARTICIPANTS = true;
export const ACTIVE_STATUS = true;
export const PARTICIPANTS_FLAG = true;
export const AUTO_PICK_WINNERS = true;
export const IMPORT_FCFS = true;
export const TRANSFER_OWNERSHIP = true;
export const SOLANA = true;
export const DELETE_ADMIN = true;
export const HIDE_STATISTIC = false;
export const PERMISSIONS = false;
export const WHITELIST_WALLETS_TAB = true;
export const RECEIVER_WALLETS_TAB = true;
export const TIERS_RANGE = true;
export const BASE_CHAIN = true;
export const DAO_CHAIN = true;
export const XLAYER_CHAIN = true;
export const ETH_IN_RPC = false;
export const WHITELABEL_CONFIG = false;
export const ADMIN_LOGS = true;
export const SOCIAL_LINKS = true;
export const ZKSYNC = true;
export const SETTING_CONFIG = true;
export const MANUAL_WINNERS = true;
export const MAX_CAP = true;
export const GIVEAWAY_FEATURE = true;
export const GIVEAWAY_VERSION: any = 'giveaway_v2';
export const GIVEAWAY_POOL = true;
export const BLAST_CHAIN = true;
export const BERA_CHAIN = true;
export const FEATURE_POOL = false;
export const BLOG = true;
export const EXCHANGE_LIST = true;
export const LINEA_CHAIN = true;
export const isScheduleTime = true;
export const TiersRangeListing = false;
export const V2PoolsToggle = true;
export const GIVEAWAY_TOOGLE_FLAG = true;
export const CUSTOM_NETWORK = true;
export const TEAM_MEMBER_SECTION = false;


// Post Release Items
export const UTC_TIMEZONE = true;
export const DELEGATE_LIST = true;
export const DISCLAIMER_TERMS = true;
export const LIST_USER = true;
export const REFRESHED_BUTTON = true;
export const USER_UNLOCKED = true;
export const STAKING_POOL_LIST = true;
export const TITLE_SLUG = true;
export const KYC_TEIRS_COLUMN = true;
export const POST_IDO_REPORT = true;
export const STAKING_POOL_CACHE = true;
export const SOCIAL_MEDIA_STATS = true;
export const BLOCK_USERS = true;
export const KYC_STATUS_ACTIVE = true;
export const ROI_DASHBOARD = false;
export const CGPT_GIFTS = true;
export const SONIC_CHAIN = true;

export const GIVEAWAY_SUPPORTED_CHAINS: any = {
  eth: true,
  bsc: true,
  polygon: true,
  avalanche: true,
  arbitrum: true,
  solana: true,
  base: true,
  coredao: true,
  xlayer: true,
  zksync: true,
  linea: true,
  blast: true,
  bera: true,
  sonic: true,
};
