import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  Tooltip,
  Button,
  CircularProgress,
} from "@material-ui/core";
import useComponentVisible from "../../../hooks/useComponentVisible";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import moment from "moment";

import useStyles from "./style";

import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import { useDispatch } from "react-redux";
import { cacheBrowserData } from "../../../request/cache";
import { KYC_STATUS_ACTIVE, REFRESHED_BUTTON, USER_UNLOCKED } from "../../../constants";

type UserProps = {
  id: string;
  email: string;
  user_telegram?: string;
  wallet_address: string;
  is_kyc: number;
  address_country: string;
  total_staked_amount: any;
  total_unlocked_amount: any;
  tier?: number;
  staked_point?: string;
  bonus_point?: string;
  total_point?: string;
  updated_at?: number | null;
};

type UserRowProps = {
  user: UserProps;
  getUserListInfo: any;
  handleCacheClear: any;
};

const UserRecord: React.FC<UserRowProps> = (props: UserRowProps) => {
  const { user, handleCacheClear } = props;
  const classes = useStyles();
  const { ref } = useComponentVisible();
  const dispatch = useDispatch();

  const maskWalletAddress = (wallet: string) => {
    const preWalletLength = wallet.length;

    let r = wallet.slice(5, preWalletLength - 5);
    wallet = wallet.replace(r, "*****");

    return wallet;
  };

  const stringifyTier = (tier: any) => {
    switch (tier) {
      case 1:
        return "1";
      case 2:
        return "2";
      case 3:
        return "3";
      case 4:
        return "4";
      default:
        return "0";
    }
  };

  {
    /* {
    "status": 200,
    "message": "Success !",
    "data": {
        "id": 15134,
        "wallet_address": "0x783935b2d25A0fbEE1911fa8664b6eDae3551c26",
        "tier": 0,
        "staked_point": "0",
        "bonus_point": 0,
        "total_point": "0",
        "created_at": "2024-11-22 07:49:04",
        "updated_at": "2024-11-29 12:40:12"
    }
} */
  }

  const [cacheLoading, setCacheLoading] = useState<{
    [key: number | string]: boolean;
  }>({});

  const handleRefreshedClick = async (user: any) => {
    setCacheLoading((prev: any) => ({ ...prev, [user.id]: true }));
    console.log(cacheLoading);
    handleCacheClear(user).finally(() => {
      setCacheLoading((prev: any) => ({ ...prev, [user.id]: false }));
    });
  };

  return (
    <>
      <TableRow ref={ref} className={classes.tableRow} key={user.id}>
        <TableCell className={classes.tableCell} align="left">
          <Tooltip
            title={<p style={{ fontSize: 15 }}>{user.wallet_address}</p>}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{maskWalletAddress(user.wallet_address)}</span>
              <CopyToClipboard
                onCopy={() => NotificationManager.success("Copied")}
                text={user.wallet_address}
              >
                <Button style={{ minWidth: "20px" }}>
                  <img
                    src="/images/icon-copy.svg"
                    alt=""
                    style={{ maxHeight: "18px" }}
                  />
                </Button>
              </CopyToClipboard>
            </div>
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          <strong>{user.total_point}</strong>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          <strong>{user.bonus_point}</strong>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          {stringifyTier(user.tier)}
        </TableCell>

        {USER_UNLOCKED && (
          <>
            <TableCell className={classes.tableCell} align="left">
              <strong>{Number(user?.total_staked_amount).toFixed(2)}</strong>
            </TableCell>

            <TableCell className={classes.tableCell} align="left">
              <strong>{Number(user?.total_unlocked_amount).toFixed(2)}</strong>
            </TableCell>
          </>
        )}
        {KYC_STATUS_ACTIVE && user?.is_kyc !== undefined && (
          <TableCell className={classes.tableCell} align="left">
            {user?.is_kyc === 1 && <strong style={{
              fontSize: "13px",
            }}>Approved</strong>}
            {user?.is_kyc === 0 && <strong>Not Approved</strong>}
          </TableCell>
        )}

        <TableCell className={classes.tableCell} align="left">
          {user.email && (
            <Tooltip title={<p style={{ fontSize: 15 }}>{user.email}</p>}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className={classes.wordBreak}>{user.email}</span>
                <CopyToClipboard
                  onCopy={() => NotificationManager.success("Copied")}
                  text={user.email}
                >
                  <Button style={{ minWidth: "20px" }}>
                    <img
                      src="/images/icon-copy.svg"
                      alt=""
                      style={{ maxHeight: "18px" }}
                    />
                  </Button>
                </CopyToClipboard>
              </div>
            </Tooltip>
          )}
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          <>
            {user.user_telegram && (
              <Tooltip
                title={<p style={{ fontSize: 15 }}>{user.user_telegram}</p>}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>{user.user_telegram}</span>
                  <CopyToClipboard
                    onCopy={() => NotificationManager.success("Copied")}
                    text={user.user_telegram}
                  >
                    <Button style={{ minWidth: "20px" }}>
                      <img
                        src="/images/icon-copy.svg"
                        alt=""
                        style={{ maxHeight: "18px" }}
                      />
                    </Button>
                  </CopyToClipboard>
                </div>
              </Tooltip>
            )}
          </>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          {user.updated_at
            ? moment(user.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                "HH:mm DD/MM"
              )
            : "null"}
        </TableCell>
        {REFRESHED_BUTTON && (
          <TableCell className={classes.tableCell} align="left">
            <button
              className={classes.exportBtn}
              style={{ color: "#000", marginLeft: "10px" }}
              onClick={() => {
                handleRefreshedClick(user);
              }}
            >
              <span> {cacheLoading[user.id] ? "Refresh" : "Refresh"}</span>
              <span style={{ marginLeft: "10px" }}>
                {cacheLoading[user.id] && (
                  <CircularProgress size={20} color="primary" />
                )}
              </span>
            </button>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default UserRecord;
