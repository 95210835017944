import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useStyles from "./style";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { useHistory, withRouter } from "react-router-dom";

import {
  deleteSocailStatsbyID,
  getSocailStats,
} from "../../request/social-stats";
import Breadcrumb from "../../components/Base/BreadCrumbs";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";

const tableHeaders = ["#", "Name", "Url", "Follower Count", "Actions"];
const SocailMediaStats = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [socialListStore, setsocialListStore] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(page);
  const [loading, setLoading] = useState(false);

  const getAdminListInfo = async (currentPage: any) => {
    const queryParams = {
      page: currentPage,
    };

    try {
      setLoading(true);
      const resObject = await getSocailStats(queryParams);

      if (resObject.status === 200) {
        const { page, lastPage, data } = resObject.data;

        const parsedData = data.map((item: any) => {
          return {
            ...item,
            socialStats: parseSocialStats(item.socialStats),
          };
        });

        setPage(page);
        setLastPage(lastPage);
        setsocialListStore(parsedData);
      }
    } catch (err) {
      console.error("Error fetching admin list info:", err);
    } finally {
      setLoading(false); // Ensure loading is reset in all cases
    }
  };
  const handleCampaignQuery = (currentPage: number) => {
    getAdminListInfo(currentPage);
  };

  useEffect(() => {
    handleCampaignQuery(currentPage);
  }, [dispatch, currentPage]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleDelete = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        const response = await deleteSocailStatsbyID(id);
        if (response?.status === 200) {
          dispatch(alertSuccess("Item deleted successfully."));
          getAdminListInfo(1); // Refresh data after deletion
        } else {
          dispatch(alertFailure("Failed to delete item."));
        }
      } catch (error) {
        dispatch(alertFailure("An error occurred while deleting the item."));
        console.error("Delete error:", error);
      }
    }
  };

  const handleEditStatsList = (id: any) => {
    const selectedStats = socialListStore?.find((item: any) => item.id === id);
    if (selectedStats) {
      history.push({
        pathname: `/social-media-stats/edit/${id}`,
        state: { socailStateLog: selectedStats },
      });
    }
  };

  const parseSocialStats = (socialStats: any) => {
    if (typeof socialStats === "string") {
      try {
        return JSON.parse(socialStats);
      } catch (error) {
        console.error("Failed to parse socialStats:", error);
        return null; // Return null or a default value if parsing fails
      }
    }
    return socialStats; // Return as-is if it's already an object
  };
  return (
    <DefaultLayout>
      <Breadcrumb title="dashboard" breadcrumbItem="Socail Stats" />

      {/* <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: "30px" }}
        onClick={() => {
          history.push("/social-media-stats/add");
        }}
      >
        Add Social Item
      </Button> */}
      <TableContainer component={Paper}>
        {loading ? (
          [...Array(10)].map((num, index) => (
            <div key={index}>
              <Skeleton className={classes.skeleton} width={"100%"} />
            </div>
          ))
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {tableHeaders.map((tHeadCell, index) => (
                  <TableCell key={index} className={classes.tableHeader}>
                    {tHeadCell}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className={classes.tableBody}>
              {socialListStore?.map((list: any) => (
                <TableRow className={classes.tableRow} key={list.id}>
                  <TableCell className={classes.tableCell} align="left">
                    <span className={`${classes.tableCellSpan}`}>
                      {list.id}
                    </span>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    <span className={`${classes.tableCellSpan}`}>
                      {list?.socialMediaName || ""}
                    </span>
                  </TableCell>
                  {/* <TableCell className={classes.tableCell} align="left">
                      <span className={`${classes.tableCellSpan}`}>
                        <img
                          width={40}
                          height={40}
                          alt={list.socialMediaName}
                          src={list.socialImg}
                        ></img>
                      </span>
                    </TableCell> */}
                  {/* <TableCell className={classes.tableCell} align="left">
                      <span className={`${classes.tableCellSpan}`}>
                        {list?.subtitle || ""}
                      </span>
                    </TableCell> */}
                  <TableCell className={classes.tableCell} align="left">
                    <span className={`${classes.tableCellSpan}`}>
                      {list?.iconArrow || ""}
                    </span>
                  </TableCell>

                  {/* <TableCell className={classes.tableCell} align="left">
                      <span className={`${classes.tableCellSpan}`}>
                        {list?.socialStats?.type}
                      </span>
                    </TableCell> */}
                  <TableCell className={classes.tableCell} align="left">
                    <span className={`${classes.tableCellSpan}`}>
                      {list?.socialStats?.count}
                    </span>
                  </TableCell>

                  <TableCell align="left">
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        handleEditStatsList(list.id);
                      }}
                    >
                      Edit
                    </Button>
                    {/* <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDelete(list?.id)}
                      >
                        Delete
                      </Button> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <>
        {socialListStore.length > 0 && socialListStore && lastPage > 1 && (
          <Pagination
            page={currentPage}
            className={classes.pagination}
            count={lastPage}
            onChange={handlePaginationChange}
          />
        )}
      </>
    </DefaultLayout>
  );
};

export default withRouter(SocailMediaStats);
