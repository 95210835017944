import React, { useEffect, useState, useCallback, useMemo } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { alertFailure } from "../../../store/actions/alert";
import { getGiveawayLinkedPool } from "../../../request/pool";

interface GiveawaySelectBoxProps {
  poolDetail: {
    id: string | number;
    isGiveawayToggleOn: boolean;
    linked_pool_id?: number;
  };
  setValue: (field: string, value: any) => void;
  watch: (field: string) => boolean;
  register: any;
  getValues:any,
  setIsChecked:any
}

const GiveawaySelectBox: React.FC<GiveawaySelectBoxProps> = ({
  poolDetail,
  watch,
  setValue,
  register,
  getValues,
  setIsChecked
}) => {
  const [availableLinkedPools, setAvailableLinkedPools] = useState<
    { value: number; label: string; campaignId: number }[]
  >([]);
  const [selectedLinkedPool, setSelectedLinkedPool] = useState<{
    value: number;
    label: string;
    campaignId: number;
  } | null>(null);

  const isLinkedPoolToggleActive = watch("linked_pool_togle");
  const dispatch = useDispatch();

  useEffect(() => {
    register("linked_pool_id");
  }, [register]);

  const fetchLinkedPools = useCallback(
    async (poolId: string | number) => {
      try {
        const { data } = await getGiveawayLinkedPool(poolId);
        const formattedPools = data.map((pool: any) => ({
          value: pool.id,
          label: pool.title,
          campaignId: pool.campaignId,
        }));
        setAvailableLinkedPools(formattedPools);
      } catch (error) {
        console.error("Failed to fetch linked pools:", error);
        dispatch(
          alertFailure(
            "Error fetching available linked pools. Please try again later."
          )
        );
        setValue('linked_pool_togle',false)
        setIsChecked(false)
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (availableLinkedPools.length > 0 && poolDetail?.linked_pool_id) {
      const preSelectedPool = availableLinkedPools.find((pool) => pool.value === poolDetail.linked_pool_id);
      if (preSelectedPool) {
        setSelectedLinkedPool(preSelectedPool);
        setValue("linked_pool_id", preSelectedPool);
      }
    }
  }, [availableLinkedPools, poolDetail?.linked_pool_id, setValue]);

  useEffect(() => {
    fetchLinkedPools(poolDetail.id || 0);
  }, [poolDetail.id, fetchLinkedPools, isLinkedPoolToggleActive]);

  const selectOptions = useMemo(() => availableLinkedPools, [availableLinkedPools]);

  const handleSelectChange = (selected: any) => {
    setSelectedLinkedPool(selected);
    setValue("linked_pool_id", selected);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px", flex: "1" }}>
      <Select
        styles={{
          container: (provided) => ({
            ...provided,
            width: "100%",
            height: "50px",
          }),
          control: (provided) => ({
            ...provided,
            width: "100%",
            height: "50px",
          }),
        }}
        options={selectOptions}
        placeholder="Select a linked pool"
        value={selectedLinkedPool}
        onChange={handleSelectChange}
        name="linked_pool_id"
      />
    </div>
  );
};

export default React.memo(GiveawaySelectBox);
