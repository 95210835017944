import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import useStyles from "../style";
import UserParticipant from "./UserWinner/UserParticipant";
import UserWinner from "./UserWinner/UserWinner";
import UserFCFS from "./UserWinner/UserFCFS";

import { Tabs } from "antd";
import UserBuyer from "./UserWinner/UserBuyer";
import PublicWinnerSetting from "./UserWinner/PublicWinnerSetting";
import { exportWinner, getWinnerUser } from "../../../request/participants";
import { UseCheckPermissions } from "../../../utils/useCheckPermissions";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import { importWinners } from "../../../helper/functions";
import useGetList from "./hooks/useGetList";
import { MANUAL_WINNERS, POST_IDO_REPORT } from "../../../constants";
import UserBonus from "./UserWinner/UserBonus";
import PostIdoReport from "./UserWinner/PostIdoReport";
import DownloadCsv from "./DownloadCsv";

const { TabPane } = Tabs;

const UserJoinPool = (props: any) => {
  const [activeTab, setActiveTab] = useState("1");
  const [selectedFile, setSelectedFile] = useState(null);
  const [importResponse, setImportResponse] = useState("");
  const fileInputRef = useRef<any>(null);

  const classes = useStyles();
  const { setValue, errors, control, watch, poolDetail, isEditUserTab } = props;
  const {
    rows,
    search,
    searchDelay,
    failure,
    loading,
    lastPage,
    currentPage,
    totalRecords,
    handlePaginationChange,
    status,
    setStatus,
  } = useGetList({ poolDetail, handleSearchFunction: getWinnerUser });

  const dispatch = useDispatch();
  const callback = (key: any) => {
    console.log(key);
    setActiveTab(key); // Update active tab
  };

  const handleSelectCSVFile = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const [importWinnersLoading, setImportWinnersLoading] = useState(false);
  const handleImportCSV = async () => {
    if (selectedFile) {
      try {
        setImportWinnersLoading(true);
        setImportResponse("");
        const res = await importWinners(poolDetail.id, selectedFile);
        search();
        if (res.status === 200) {
          setImportWinnersLoading(false);
          dispatch(alertSuccess("Winners Imported Successfully"));
        } else {
          setImportWinnersLoading(false);
          dispatch(alertFailure("Import Winners Failed"));
        }
        fileInputRef.current.value = "";
      } catch (error) {
        setImportWinnersLoading(false);
        fileInputRef.current.value = "";
      }
    } else {
      dispatch(alertFailure("Please select file"));
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={callback}
        style={{
          minHeight: 500,
        }}
      >
        <TabPane tab="Participant" key="1">
          <UserParticipant
            poolDetail={poolDetail}
            isEditUserTab={isEditUserTab}
            setValue={setValue}
            errors={errors}
            control={control}
            watch={watch}
          />
        </TabPane>
        <TabPane tab="Winner" key="2">
          <div
            style={{
              paddingBottom: 20,
            }}
          >
            {MANUAL_WINNERS && (
              <Grid item xs={6} style={{ display: "inline-block" }}>
                <div
                  style={{
                    display: "inline-flex",
                    gap: "5px",
                    flexDirection: "column",
                  }}
                >
                  <input
                    color="primary"
                    type="file"
                    accept=".csv"
                    onChange={handleSelectCSVFile}
                    ref={fileInputRef}
                  />

                  {poolDetail?.supported_token_types === "solana" ? (
                    <DownloadCsv
                      label="Download Sample File"
                      filePath={
                        "https://chaingpt-launchpad.s3.dualstack.us-east-2.amazonaws.com/admin-csv-samples/solana-import_winner.csv"
                      }
                      fileName="import_solana_winner.csv"
                    ></DownloadCsv>
                  ) : (
                    <DownloadCsv
                      label="Download Sample File"
                      filePath={
                        "https://chaingpt-launchpad.s3.dualstack.us-east-2.amazonaws.com/admin-csv-samples/import_winner.csv"
                      }
                      fileName="import_winner.csv"
                    ></DownloadCsv>
                  )}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleImportCSV}
                  disabled={!selectedFile || isEditUserTab}
                >
                  {importWinnersLoading ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.loader}></div>{" "}
                      <span style={{ marginLeft: "5px" }}>Uploading</span>
                    </div>
                  ) : (
                    "Import Winners"
                  )}
                </Button>
              </Grid>
            )}
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => exportWinner(poolDetail.id)}
              disabled={isEditUserTab}
            >
              Export Winner
            </Button>
          </div>
          <div
            style={{
              paddingBottom: 20,
            }}
          >
            <PublicWinnerSetting
              poolDetail={poolDetail}
              setValue={setValue}
              errors={errors}
              control={control}
              isEditDisabled={isEditUserTab}
            />
          </div>
          <UserWinner
            poolDetail={poolDetail}
            callback={callback}
            activeTab={activeTab}
            isEditDisabled={isEditUserTab}
            search={search}
            searchDelay={searchDelay}
            rows={rows}
            status={status}
            setStatus={setStatus}
            failure={failure}
            loading={loading}
            currentPage={currentPage}
            lastPage={lastPage}
            handlePaginationChange={handlePaginationChange}
          />
        </TabPane>
        <TabPane tab="FCFS Allocation" key="3">
          <UserFCFS
            poolDetail={poolDetail}
            isEditFCFSDisabled={isEditUserTab}
            isEditFCFSJOBDisabled={isEditUserTab}
          />
        </TabPane>
        <TabPane tab="User Bonus" key="4">
          <UserBonus poolDetail={poolDetail} isEditUserTab={isEditUserTab} />
        </TabPane>
        {POST_IDO_REPORT && (
          <TabPane tab="Post Ido Data" key="5">
            <PostIdoReport
              poolDetail={poolDetail}
              isEditFCFSDisabled={isEditUserTab}
              isEditFCFSJOBDisabled={isEditUserTab}
            />
          </TabPane>
        )}
        {/* <TabPane tab="Buyer" key="3">
          <UserBuyer poolDetail={poolDetail} />
        </TabPane> */}
      </Tabs>
    </>
  );
};

export default UserJoinPool;
