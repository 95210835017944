import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import DefaultLayout from "../../../components/Layout/DefaultLayout";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import {
  createRoiPrediction,
  getRoiPredictionById,
  updateRoiPredictionById,
} from "../../../request/roi-prediction";
import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import { renderErrorCreatePool } from "../../../utils/validate";
import useStyles from "./style";
import { useHistory, useParams } from "react-router";

const ROIPredictionForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useHistory();
  // @ts-ignore
  const { id } = useParams();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      blockchains: "no",
      category: "",
      market_cap: "",
      price: "",
      total_raise: "",
      sale_price: "",
      follower_count: "",
      initial_cap: "",
      score: "",
    },
  });

  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (!id) return;

    const fetchData = async () => {
      try {
        const res = await getRoiPredictionById(id);
        if (res.status === 200 && res.data) {
          const formattedData = Object.keys(res.data).reduce((acc, key) => {
            const value = res.data[key];
          
            // @ts-ignore
            acc[key] =
              !isNaN(value) && value !== null && value !== ""
                ? parseFloat(value).toFixed(2)
                : value;
          
            return acc;
          }, {});
          
          console.log({formattedData})
          // @ts-ignore
          reset({ ...formattedData, sale_price: formattedData?.sale_Price });
        } else {
          dispatch(alertFailure("Failed to fetch ROI prediction data"));
        }
      } catch (error) {
        dispatch(alertFailure("Error fetching ROI prediction data"));
      }
    };

    fetchData();
  }, [id, reset, dispatch]);

  const onSubmit = async (data: any) => {
    try {
      let res;
      if (id) {
        // Update existing entry
        res = await updateRoiPredictionById(id, data);
      } else {
        // Create new entry
        res = await createRoiPrediction(data);
      }

      if (res.status >= 400) {
        dispatch(alertFailure(res.message));
        return;
      }

      dispatch(
        alertSuccess(id ? "Updated successfully" : "Created successfully")
      );
      router.push("/dashboard/roi-dashboard");
    } catch (error) {
      dispatch(alertFailure("Something went wrong"));
    }
  };

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.mainStatistic}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset" error={!!errors.blockchains}>
              <Controller
                name="blockchains"
                control={control}
                rules={{ required: "Blockchains is required" }}
                render={(field) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value === "yes"}
                        onChange={(e) =>
                          field.onChange(e.target.checked ? "yes" : "no")
                        }
                      />
                    }
                    label="Blockchains"
                  />
                )}
              />
              {errors.blockchains && (
                <p
                  style={{ padding: 0, margin: 0 }}
                  className={classes.formErrorMessage}
                >
                  {renderError(errors, "blockchains")}
                </p>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ marginBottom: "10px" }}>
          <FormControl component="fieldset">
            <label className={classes.formControlLabel}>Category</label>
            <Controller
              control={control}
              name="category"
              rules={{
                required: "Category is required",
              }}
              render={(field) => (
                <select {...field} className={`${classes.formInputBox}`}>
                  <option value="">Select a category</option>
                  {[
                    "GameFi",
                    "Chain",
                    "Blockchain Infrastructure",
                    "Blockchain Service",
                    "Stablecoin",
                    "NFT",
                    "Meme",
                    "DeFi",
                    "CeFi",
                    "Social",
                    "Currency",
                  ].map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              )}
            />
            {errors.category && (
              <p
                style={{ padding: 0, margin: 0 }}
                className={classes.formErrorMessage}
              >
                {renderError(errors, "category")}
              </p>
            )}
          </FormControl>

          <FormControl component="fieldset" error={!!errors.market_cap}>
            <label className={classes.formControlLabel}>Market Cap</label>
            <Controller
              control={control}
              name="market_cap"
              rules={{
                required: "Market Cap is required",
              }}
              render={(field) => (
                <input
                  {...field}
                  type="number"
                  className={`${classes.formInputBox}`}
                  placeholder="Please enter market cap"
                />
              )}
            />
            {errors.market_cap && (
              <p
                style={{ padding: 0, margin: 0 }}
                className={classes.formErrorMessage}
              >
                {renderError(errors, "market_cap")}
              </p>
            )}
          </FormControl>

          <FormControl component="fieldset">
            <label className={classes.formControlLabel}>Price</label>
            <Controller
              control={control}
              name="price"
              rules={{
                required: "Price is required",
              }}
              render={(field) => (
                <input
                  {...field}
                  type="number"
                  className={`${classes.formInputBox}`}
                  placeholder="Please enter price"
                />
              )}
            />
            {errors.price && (
              <p
                style={{ padding: 0, margin: 0 }}
                className={classes.formErrorMessage}
              >
                {renderError(errors, "price")}
              </p>
            )}
          </FormControl>
        </div>

        <div style={{ marginBottom: "10px" }}>
          <FormControl component="fieldset">
            <label className={classes.formControlLabel}>Raise</label>
            <Controller
              control={control}
              name="total_raise"
              rules={{
                required: "Total raise is required",
              }}
              render={(field) => (
                <input
                  {...field}
                  type="number"
                  className={`${classes.formInputBox}`}
                  placeholder="Please enter raise"
                />
              )}
            />
            {errors.total_raise && (
              <p
                style={{ padding: 0, margin: 0 }}
                className={classes.formErrorMessage}
              >
                {renderError(errors, "total_raise")}
              </p>
            )}
          </FormControl>

          <FormControl component="fieldset">
            <label className={classes.formControlLabel}>Sale Price</label>
            <Controller
              control={control}
              name="sale_price"
              rules={{
                required: "Sale Price is required",
              }}
              render={(field) => (
                <input
                  {...field}
                  type="number"
                  className={`${classes.formInputBox}`}
                  placeholder="Please enter sale price"
                />
              )}
            />
            {errors.sale_price && (
              <p
                style={{ padding: 0, margin: 0 }}
                className={classes.formErrorMessage}
              >
                {renderError(errors, "sale_price")}
              </p>
            )}
          </FormControl>

          <FormControl component="fieldset">
            <label className={classes.formControlLabel}>Follower Count</label>
            <Controller
              control={control}
              name="follower_count"
              rules={{
                required: "Follower Count is required",
              }}
              render={(field) => (
                <input
                  {...field}
                  type="number"
                  className={`${classes.formInputBox}`}
                  placeholder="Please enter follower count"
                />
              )}
            />
            {errors.follower_count && (
              <p
                style={{ padding: 0, margin: 0 }}
                className={classes.formErrorMessage}
              >
                {renderError(errors, "follower_count")}
              </p>
            )}
          </FormControl>
        </div>
        <FormControl component="fieldset">
          <label className={classes.formControlLabel}>Initial Cap</label>
          <Controller
            control={control}
            name="initial_cap"
            rules={{
              required: "Initail Cap is required",
            }}
            render={(field) => (
              <input
                {...field}
                type="number"
                className={`${classes.formInputBox}`}
                placeholder="Please enter initial cap"
              />
            )}
          />
          {errors.initial_cap && (
            <p
              style={{ padding: 0, margin: 0 }}
              className={classes.formErrorMessage}
            >
              {renderError(errors, "initial_cap")}
            </p>
          )}
        </FormControl>

        <FormControl component="fieldset">
          <label className={classes.formControlLabel}>Score</label>
          <Controller
            control={control}
            name="score"
            rules={{
              required: "Score is required",
            }}
            render={(field) => (
              <input
                {...field}
                type="number"
                step="0.1"
                className={`${classes.formInputBox}`}
                placeholder="Please enter score"
              />
            )}
          />
          {errors.score && (
            <p
              style={{ padding: 0, margin: 0 }}
              className={classes.formErrorMessage}
            >
              {renderError(errors, "score")}
            </p>
          )}
        </FormControl>
        <Grid container>
          <Grid item xs={12}>
            <button type="submit" className={classes.formButtonUpdateStatistic}>
            {id  ? 'Update' : 'Create'}
            </button>
          </Grid>
        </Grid>
      </form>
    </DefaultLayout>
  );
};

export default ROIPredictionForm;
