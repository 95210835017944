import React, { useEffect, useState } from "react";
import { Button, TableCell, TableRow, Tooltip } from "@material-ui/core";
import useComponentVisible from "../../../hooks/useComponentVisible";
import { Link, useHistory } from "react-router-dom";

import useStyles from "./style";
import { adminRoute } from "../../../utils";
import { deleteAdmin } from "../../../request/admin";
import AdminDeleteRowForm from "./AdminDeleteRowForm";
import { useDispatch, useSelector } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import { deleteRoiPredictionById } from "../../../request/roi-prediction";

type AdminProps = {
  id: string;
  blockchains: string | boolean;
  category: string;
  market_cap: number | string;
  total_raise: number | string;
  score: number | string;
  initial_cap: number | string;
  follower_count: number | string;
  price: number | string;
  sale_Price: number | string;
  roi_category:string
};

type AdminRowProps = {
  roidata: AdminProps;
  currentOpen: string;
  setCurrentOpen: (id: string) => void;
};

const ROIRecord: React.FC<AdminRowProps> = (props: AdminRowProps) => {
  const { roidata, currentOpen, setCurrentOpen } = props;
  const classes = useStyles();

  const [idDelete, setIdDelete] = useState<string>("");
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isOpenEditPopup, setIsOpenEditPopup] = useState<boolean>(false);
  const router = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    currentOpen && setCurrentOpen("");
  }, [roidata]);

  const confirmDelete = (id: string = "") => {
    setIsOpenEditPopup(true);
    setIdDelete(id);
  };

  const onConfirmDelete = async () => {
    setDeleteLoading(true);
    const response = await deleteRoiPredictionById(idDelete);
    if (response?.status === 200) {
      dispatch(alertSuccess("Roi prediction deleted successfully!"));
      window.location.reload();
    } else {
      dispatch(alertFailure(response?.message || "Fail!"));
    }
    setDeleteLoading(false);
    setIsOpenEditPopup(false);
  };

  const handleDelete = (e: any, id: any) => {
    e.preventDefault();
    confirmDelete(id);
  };

  return (
    <>
      <AdminDeleteRowForm
        isOpenEditPopup={isOpenEditPopup}
        onConfirmDelete={onConfirmDelete}
        setIsOpenEditPopup={setIsOpenEditPopup}
      />
      <TableRow
        className={classes.tableRow}
        key={roidata.id}
        component={Link}
        to={adminRoute(`/roi-details/${roidata.id}`)}
      >
        <TableCell className={classes.tableCell} align="left">
          {roidata.id}
        </TableCell>

        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
        >
          <Tooltip
            title={
              <p style={{ fontSize: 15 }}>
                {roidata?.category ? roidata?.category : "---"}
              </p>
            }
          >
            <span className={classes.wordBreak}>
              {roidata.category ? roidata.category : "---"}
            </span>
          </Tooltip>
        </TableCell>
        
        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
        >
          <Tooltip
            title={
              <p style={{ fontSize: 15 }}>
                {roidata.blockchains ? roidata.blockchains : "---"}
              </p>
            }
          >
            <span className={classes.wordBreak}>
              {roidata.blockchains ? roidata.blockchains : "---"}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
        >
          <Tooltip
            title={
              <p style={{ fontSize: 15 }}>
                {roidata.market_cap
                  ? parseFloat(roidata.market_cap as string).toFixed(2)
                  : "---"}
              </p>
            }
          >
            <span className={classes.wordBreak}>
              {roidata.market_cap
                ? parseFloat(roidata.market_cap as string).toFixed(2)
                : "---"}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
        >
          <Tooltip
            title={
              <p style={{ fontSize: 15 }}>
                {roidata.price
                  ? parseFloat(roidata.price as string).toFixed(2)
                  : "---"}
              </p>
            }
          >
            <span className={classes.wordBreak}>
              {roidata.price
                ? parseFloat(roidata.price as string).toFixed(2)
                : "---"}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
        >
          <Tooltip
            title={
              <p style={{ fontSize: 15 }}>
                {roidata.total_raise
                  ? parseFloat(roidata.total_raise as string).toFixed(2)
                  : "---"}
              </p>
            }
          >
            <span className={classes.wordBreak}>
              {roidata.total_raise
                ? parseFloat(roidata.total_raise as string).toFixed(2)
                : "---"}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
        >
          <Tooltip
            title={
              <p style={{ fontSize: 15 }}>
                {roidata.sale_Price
                  ? parseFloat(roidata.sale_Price as string).toFixed(2)
                  : "---"}
              </p>
            }
          >
            <span className={classes.wordBreak}>
              {roidata.sale_Price
                ? parseFloat(roidata.sale_Price as string).toFixed(2)
                : "---"}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
        >
          <Tooltip
            title={
              <p style={{ fontSize: 15 }}>
                {roidata.follower_count
                  ? parseFloat(roidata.follower_count as string).toFixed(2)
                  : "---"}
              </p>
            }
          >
            <span className={classes.wordBreak}>
              {roidata.follower_count
                ? parseFloat(roidata.follower_count as string).toFixed(2)
                : "---"}
            </span>
          </Tooltip>
        </TableCell>


        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
        >
          <Tooltip
            title={
              <p style={{ fontSize: 15 }}>
                {roidata.initial_cap
                  ? parseFloat(roidata.initial_cap as string).toFixed(2)
                  : "---"}
              </p>
            }
          >
            <span className={classes.wordBreak}>
              {roidata.initial_cap
                ? parseFloat(roidata.initial_cap as string).toFixed(2)
                : "---"}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
        >
          <Tooltip
            title={
              <p style={{ fontSize: 15 }}>
                {roidata.score
                  ? parseFloat(roidata.score as string).toFixed(2)
                  : "---"}
              </p>
            }
          >
            <span className={classes.wordBreak}>
            {roidata.score
                  ? parseFloat(roidata.score as string).toFixed(2)
                  : "---"}
            </span>
          </Tooltip>
        </TableCell>

        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
        >
          <Tooltip
            title={
              <p style={{ fontSize: 15 }}>
                {roidata?.roi_category ? roidata?.roi_category : "---"}
              </p>
            }
          >
            <span className={classes.wordBreak}>
              {roidata.roi_category ? roidata.roi_category : "---"}
            </span>
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          <div className={classes.tableCellFlex}>
            <div className="left">
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "10px" }}
                // onClick={() => {
                //   router.push(`/exchange-list/edit/${roidata?.id}`);
                // }}
                // disabled={!exchange?.isActive}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => handleDelete(e, roidata.id)}
                disabled={deleteLoading}
              >
                Delete
              </Button>
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ROIRecord;
