import React, { useEffect } from "react";
import useStyles from "../style";
import { isGiveawayPool, renderErrorCreatePool } from "../../../utils/validate";
import { Controller } from "react-hook-form";
import { Select, MenuItem } from "@material-ui/core";
import { CGPT_GIFTS } from "../../../constants";

function PoolRelationship(props: any) {
  const classes = useStyles();
  const { errors, poolDetail, control, setValue, isEditDisabled, isDeployed } = props;
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && poolDetail.relationship_type) {
      setValue("relationship_type", poolDetail.relationship_type);
    }
  }, [poolDetail, setValue]);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Relationship Type</label>
        <Controller
          control={control}
          defaultValue="None"
          className={classes.formControlInput}
          name="relationship_type"
          as={
            <Select
              labelId="relationship_type"
              id="relationship_type"
              name="relationship_type"
              disabled={isEditDisabled}
              style={{
                backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
              }}
            >
              <MenuItem disabled={isDeployed && isGiveawayPool(poolDetail.relationship_type)} value="None">
                None
              </MenuItem>
              <MenuItem disabled={isDeployed && isGiveawayPool(poolDetail.relationship_type)} value="Standard IDO">
                Standard IDO
              </MenuItem>
              <MenuItem disabled={isDeployed && isGiveawayPool(poolDetail.relationship_type)} value="Flash Sale">
                Flash Sale
              </MenuItem>
              <MenuItem disabled={isDeployed && !isGiveawayPool(poolDetail.relationship_type)} value="Giveaway">
                Giveaway
              </MenuItem>
              {/* <MenuItem value="Exclusive IDO">Exclusive IDO</MenuItem> */}
              <MenuItem disabled={isDeployed && isGiveawayPool(poolDetail.relationship_type)} value="Private Sale">
                Private Sale
              </MenuItem>
              <MenuItem disabled={isDeployed && isGiveawayPool(poolDetail.relationship_type)} value="Acceleration">
                Acceleration
              </MenuItem>
              {/* <MenuItem value="Standard IDO">Standard IDO</MenuItem> */}
              <MenuItem disabled={isDeployed && isGiveawayPool(poolDetail.relationship_type)} value="Diamond IDO">
                Diamond IDO
              </MenuItem>
              <MenuItem
                disabled={isDeployed && isGiveawayPool(poolDetail.relationship_type)}
                value="Flash Sale (Incubation)"
              >
                Flash Sale (Incubation)
              </MenuItem>
              <MenuItem
                disabled={isDeployed && isGiveawayPool(poolDetail.relationship_type)}
                value="IDO (Labs Incubation)"
              >
                IDO (Labs Incubation)
              </MenuItem>
              <MenuItem
                disabled={isDeployed && !isGiveawayPool(poolDetail.relationship_type)}
                value="Giveaway (Labs Incubation)"
              >
                Giveaway (Labs Incubation)
              </MenuItem>
              <MenuItem
                disabled={isDeployed && isGiveawayPool(poolDetail.relationship_type)}
                value="Seed (Labs Incubation)"
              >
                Seed (Labs Incubation)
              </MenuItem>
              <MenuItem
                disabled={isDeployed && isGiveawayPool(poolDetail.relationship_type)}
                value="Private (Labs Incubation)"
              >
                Private (Labs Incubation)
              </MenuItem>
              <MenuItem disabled={isDeployed && !isGiveawayPool(poolDetail.relationship_type)} value="Launchdrop">
                Launchdrop
              </MenuItem>
              {CGPT_GIFTS && <MenuItem disabled={isDeployed && !isGiveawayPool(poolDetail.relationship_type)} value="CGPT Gifts">
              CGPT Gifts
              </MenuItem>}
            </Select>
          }
        />
        <p className={classes.formErrorMessage}>{renderError(errors, "relationship_type")}</p>
      </div>
    </>
  );
}

export default PoolRelationship;
