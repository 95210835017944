import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
import { convertDateTimeToUnix } from "../../utils/convertDate";
import useStyles from "./style";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import Button from "../../components/Base/ButtonLink";

import { adminRoute, validateJson } from "../../utils";
import SearchForm from "./SearchForm";
import { getAdminList } from "../../request/admin";
import { useHistory } from "react-router-dom";
import { UseCheckPermissions } from "../../utils/useCheckPermissions";
import NoPermisisons from "../../components/Base/NoPermissions";
import { PERMISSIONS } from "../../constants";
import EmptyTable from "../../components/Base/Emptytable";
import ROIRecord from "./RoiPredictionRows/RoiRow";
import { getRoiPredictionList } from "../../request/roi-prediction";

const tableHeaders = [
  "ID",
  "CATEGORY",
  "BLOCKCHAINS",
  "MARKET CAP",
  "PRICE",
  "TOTAL RAISE",
  "SALE PRICE",
  "FOLLOWER COUNT",
  "INITIAL CAP",
  "SCORE",
  "ROI CATEGORY",
  "ACTION",
];

const ROIPredictionList: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [roiList, setRoiList] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [currentOpen, setCurrentOpen] = useState("");
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [finishTime, setFinishTime] = useState<Date | null>(null);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(page);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);

  const getAdminListInfo = async (currentPage: any, query: any) => {
    const queryParams = {
      page: currentPage,
      searchQuery: query,
      limit: 10,
    };

    try {
      setLoading(true);
      const resObject = await getRoiPredictionList(queryParams);
      if (resObject.status === 200) {
        const { page, lastPage, data } = resObject.data;
        setPage(page);
        setLastPage(lastPage);
        setRoiList(data);
        setFailure(false);
      } else if (resObject.status === 401) {
        history.push(adminRoute("/login"));
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFailure(true);
    }
  };

  useEffect(() => {
    handleCampaignQuery(currentPage, query);
  }, [dispatch, currentPage, query]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleCampaignSearch = (event: any) => {
    setCurrentPage(1);
    setQuery(event.target.value);
  };

  const handleCampaignQuery = (currentPage: number, query: string) => {
    getAdminListInfo(currentPage, query);
  };

  const handleCurrentOpenSet = (id: string) => {
    setCurrentOpen(id);
  };

  const roiCategories = [
    { category: "Very Low", condition: "ROI < 1" },
    { category: "Low", condition: "1 ≤ ROI < 3" },
    { category: "Medium", condition: "3 ≤ ROI < 7" },
    { category: "Medium-High", condition: "7 ≤ ROI < 15" },
    { category: "High", condition: "15 ≤ ROI < 40" },
    { category: "Very High", condition: "ROI ≥ 40" },
  ];

  return (
    <DefaultLayout>
      <>
        <div className={classes.header}>
          <div className="header-left">
            <Button
              to={adminRoute("/roi-create")}
              text={"Create"}
              // icon={"icon_plus.svg"}
            />
          </div>
          <SearchForm
            startTime={startTime}
            setStartTime={setStartTime}
            finishTime={finishTime}
            setFinishTime={setFinishTime}
            setCurrentPage={setCurrentPage}
            handleCampaignSearch={handleCampaignSearch}
          />
        </div>

        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.tableHeader}
                  style={{ padding: 0, color: "#3A39BB" }}
                >
                  Condition
                </TableCell>
                <TableCell>Minimum</TableCell>
                <TableCell>Maximum</TableCell>
                {roiCategories.map((row, index) => (
                  <TableCell key={index} align="center">
                    {row.condition}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes.tableHeader}
                  style={{ padding: 0, color: "#3A39BB" }}
                >
                  Category
                </TableCell>
                <TableCell>1</TableCell>
                <TableCell>40</TableCell>
                {roiCategories.map((row, index) => (
                  <TableCell key={index} align="center">
                    {row.category}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} className={classes.tableContainer}>
          {loading ? (
            [...Array(10)].map((num, index) => (
              <div key={index}>
                <Skeleton className={classes.skeleton} width={"100%"} />
              </div>
            ))
          ) : (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((tableHeader: string, index: number) => (
                    <TableCell key={index} className={classes.tableHeader}>
                      {tableHeader}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody className={classes.tableBody}>
                {roiList && roiList.length > 0 ? (
                  roiList.map((roidata: any, index: number) => (
                    <ROIRecord
                      key={roidata.id + index}
                      currentOpen={currentOpen}
                      setCurrentOpen={handleCurrentOpenSet}
                      roidata={roidata}
                    />
                  ))
                ) : (
                  <EmptyTable title="admin" />
                )}
              </TableBody>
            </Table>
          )}
          {failure ? (
            <p className={classes.errorMessage}>{failure}</p>
          ) : (!roiList || roiList.length === 0) && !loading ? (
            <p className={classes.noDataMessage}>There is no data</p>
          ) : (
            <>
              {roiList.length > 0 && roiList && lastPage > 1 && (
                <Pagination
                  page={currentPage}
                  className={classes.pagination}
                  count={lastPage}
                  onChange={handlePaginationChange}
                />
              )}
            </>
          )}
        </TableContainer>
      </>
    </DefaultLayout>
  );
};

export default ROIPredictionList;
