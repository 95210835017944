import React, { useEffect, useState } from "react";
import useStyles from "../style";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import { renderErrorCreatePool } from "../../../utils/validate";
import { Switch } from "antd";
import { withRouter } from "react-router";

function GiveawayToggle(props: any) {
  const classes = useStyles();
  const { setValue, errors, control, poolDetail, register, watch,isChecked,setIsChecked } = props;
  const renderError = renderErrorCreatePool;
  useEffect(() => {
    if (poolDetail && poolDetail.linked_pool_togle !== undefined) {
      const initialValue = !!poolDetail.linked_pool_togle;
        setValue("linked_pool_togle",initialValue);
        setIsChecked(initialValue);
    }
  }, [poolDetail, setValue]);

  useEffect(() => {
    register("linked_pool_togle");
  }, [register]);

  const handleSwitchChange = async (switchValue: any, onChange: any) => {
    console.log("switchValue", switchValue, isChecked);

    if (!window.confirm("Do you want to change the toggle?")) {
      setIsChecked(!switchValue);
      return;
    }

    setIsChecked(switchValue);
    await onChange(switchValue);
  };

  return (
    <div
      style={{ marginBottom: "20px", marginTop: "20px", marginLeft: "10px" }}
    >
      <div style={{ display: "flex" }}>
        <label
          className={classes.formControlLabel}
          style={{ marginBottom: "0px" }}
        >
          Pair Giveaway With IDO 
        </label>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
          width: "fitContent",
        }}
      >
        <FormControl component="fieldset">
          <Controller
            control={control}
            name="linked_pool_togle"
            render={(field) => {
              const { value, onChange } = field;
              return (
                <Switch
                  onChange={(switchValue) =>
                    handleSwitchChange(switchValue, onChange)
                  }
                  checked={isChecked}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                />
              );
            }}
          />

          {errors?.is_display && (
            <p className={classes.formErrorMessage}>
              {renderError(errors, "linked_pool_togle")}
            </p>
          )}
        </FormControl>
      </div>
    </div>
  );
}

export default withRouter(GiveawayToggle);
